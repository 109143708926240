import React, { useEffect, useState } from 'react'
import { TextField, useTheme } from '@mui/material'
import { Grid, Typography, Box, Button, Icon, Menu, Autocomplete } from '@ntpkunity/controls'
import ProductsBundleList from './products-bundle-list'
import { ProductsBundleWrap } from './product-bundles.styles'
import { useGetAllFinancialInsuranceProductBundlesByDealerCode } from '@apis/financial-insurance.service'
import { useStoreContext } from '@store/storeContext'

interface ProductsBundleProps {
  defaultCurrency: string
  isFinanceType: boolean
  terms: any
  allFniProducts: any
  financeProductRates: any
  leaseProductRates: any
  setSelectedLeaseItems: any
  setSelectedFinanceItems: any
  selectedFinanceItems: any
  selectedLeaseItems: any
  applyRetailPrice: any
  dealerInfo?: any
  fniBundleChanges?: any
  setFniBundleChanges?: any
}

const ProductsBundle: React.FC<ProductsBundleProps> = ({
  allFniProducts,
  defaultCurrency,
  isFinanceType,
  financeProductRates,
  leaseProductRates,
  setSelectedLeaseItems,
  setSelectedFinanceItems,
  selectedFinanceItems,
  selectedLeaseItems,
  applyRetailPrice,
  dealerInfo,
  fniBundleChanges,
  setFniBundleChanges
}) => {
  const theme = useTheme()
  const { states } = useStoreContext()
  const [boxData, setBoxData] = useState<any[]>([])
  const [fniMenuAnchorEl, setFniMenuAchorEl] = useState<null | HTMLElement>(null)

  const { data: fniBundles } = useGetAllFinancialInsuranceProductBundlesByDealerCode(
    states?.dealerInfo?.dealer_code
  )
  const [selectedBundleIndex, setSelectedBundleIndex] = useState<any>(null)
  const selectedBundleFniItems = fniBundles?.[selectedBundleIndex]?.financial_products
  const selectedBundle = fniBundles?.[selectedBundleIndex]
  const disabledIds = isFinanceType
    ? selectedFinanceItems
        ?.filter((fniItem: any) => !fniItem?.bundle_id)
        .map((fniItem: any) => fniItem?.financial_insurance_id ?? fniItem?.id)
    : selectedLeaseItems
        ?.filter((fniItem: any) => !fniItem?.bundle_id)
        .map((fniItem: any) => fniItem?.financial_insurance_id ?? fniItem?.id)
  const selectedFniIds = isFinanceType
    ? selectedFinanceItems
        ?.filter((fniItem: any) => fniItem?.bundle_id)
        .map((fniItem: any) => fniItem?.financial_insurance_id ?? fniItem?.id)
    : selectedLeaseItems
        ?.filter((fniItem: any) => fniItem?.bundle_id)
        .map((fniItem: any) => fniItem?.financial_insurance_id ?? fniItem?.id)
  const selectedFniBundleItems = selectedBundle?.financial_products
    ?.filter((product: any) => !product?.bundle_fp_status)
    ?.map((product: any) => product?.financial_product_id)
  const availableFniItems = allFniProducts?.filter(
    (item: any) =>
      !disabledIds?.includes(item?.financial_insurance_id ?? item?.id) &&
      !selectedFniIds?.includes(item?.financial_insurance_id ?? item?.id) &&
      !selectedFniBundleItems?.includes(item?.financial_insurance_id ?? item?.id)
  )

  useEffect(() => {
    if (selectedBundleIndex === null) {
      return
    }
    if (!selectedBundleFniItems?.length) {
      return
    }

    const fniItems = boxData?.[selectedBundleIndex]?.items ?? []
    if (isFinanceType) {
      const updatedItems = selectedFinanceItems?.filter((item: any) => !item?.bundle_id)
      setSelectedFinanceItems([...updatedItems, ...fniItems])
    } else {
      const updatedItems = selectedLeaseItems?.filter((item: any) => !item?.bundle_id)
      setSelectedLeaseItems([...updatedItems, ...fniItems])
    }
  }, [selectedBundleFniItems, selectedBundleIndex])

  useEffect(() => {
    if (fniBundles && isFinanceType) {
      const bundleId = selectedFinanceItems?.find((item: any) => item?.bundle_id)?.bundle_id
      const index = fniBundles?.findIndex((bundle: any) => bundle?.id === bundleId)
      if (index !== -1) {
        const fniIds = fniBundles?.[index]?.financial_products?.map(
          (product: any) => product?.financial_product_id
        )
        const selectedBundleItems = selectedFinanceItems?.filter((item: any) => {
          const isAlreadyAdded = fniBundleChanges?.[bundleId]?.added?.some(
            (addedItem: any) => addedItem?.id === item?.id
          )
          return (
            item?.bundle_id &&
            !fniIds?.includes(item?.financial_insurance_id ?? item?.id) &&
            !isAlreadyAdded
          )
        })
        selectedBundleItems?.forEach((item: any) => handleAddItem(item))
      }
      setSelectedBundleIndex(index !== -1 ? index : null)
    } else if (fniBundles && !isFinanceType) {
      const bundleId = selectedLeaseItems?.find((item: any) => item?.bundle_id)?.bundle_id
      const index = fniBundles?.findIndex((bundle: any) => bundle?.id === bundleId)
      if (index !== -1) {
        const fniIds = fniBundles?.[index]?.financial_products?.map(
          (product: any) => product?.financial_product_id
        )
        const selectedBundleItems = selectedLeaseItems?.filter((item: any) => {
          const isAlreadyAdded = fniBundleChanges?.[bundleId]?.added?.some(
            (addedItem: any) => addedItem?.id === item?.id
          )
          return (
            item?.bundle_id &&
            !fniIds?.includes(item?.financial_insurance_id ?? item?.id) &&
            !isAlreadyAdded
          )
        })
        selectedBundleItems?.forEach((item: any) => handleAddItem(item))
      }
      setSelectedBundleIndex(index !== -1 ? index : null)
    }
  }, [fniBundles])

  useEffect(() => {
    if (selectedBundle) {
      const removed = fniBundleChanges?.[selectedBundle?.id]?.removed || []
      const added = fniBundleChanges?.[selectedBundle?.id]?.added || []
      const itemsInSelectedBundle = boxData?.[selectedBundleIndex]?.items || []

      const getProductId = (item: any) => {
        if (item?.product_id) return item?.product_id
        const matchingProduct = allFniProducts?.find(
          (product: any) => product?.id === (item?.financial_insurance_id ?? item?.id)
        )
        return matchingProduct?.product_id || null
      }

      if (isFinanceType) {
        const nonBundledFinanceItems = selectedFinanceItems?.filter((item: any) => !item?.bundle_id)

        const updatedFinanceItems = itemsInSelectedBundle
          ?.filter((item: any) => {
            const productId = getProductId(item)
            return !removed?.includes(productId)
          })
          ?.map((item: any) => {
            const productId = getProductId(item)
            const addedItem = added?.find((added: any) => getProductId(added) === productId)
            return addedItem || item
          })
          .concat(
            added?.filter(
              (addedItem: any) =>
                !itemsInSelectedBundle?.some(
                  (existingItem: any) => getProductId(existingItem) === getProductId(addedItem)
                )
            )
          )

        const finalFinanceItems = [...updatedFinanceItems, ...nonBundledFinanceItems]

        setSelectedFinanceItems(finalFinanceItems)
      } else {
        const nonBundledLeaseItems = selectedLeaseItems?.filter((item: any) => !item?.bundle_id)

        const updatedLeaseItems = itemsInSelectedBundle
          ?.filter((item: any) => {
            const productId = getProductId(item)
            return !removed?.includes(productId)
          })
          ?.map((item: any) => {
            const productId = getProductId(item)
            const addedItem = added?.find((added: any) => getProductId(added) === productId)
            return addedItem || item
          })
          .concat(
            added?.filter(
              (addedItem: any) =>
                !itemsInSelectedBundle?.some(
                  (existingItem: any) => getProductId(existingItem) === getProductId(addedItem)
                )
            )
          )

        const finalLeaseItems = [...updatedLeaseItems, ...nonBundledLeaseItems]
        setSelectedLeaseItems(finalLeaseItems)
      }
    }
  }, [fniBundleChanges, selectedBundle])

  const getCoverage = (cardData: any) => {
    const rates = isFinanceType ? financeProductRates : leaseProductRates
    const rate = rates?.rates?.rate
    const item = Array.isArray(rate)
      ? rate.find(
          (currentRate: any) =>
            currentRate?.product_id === cardData?.product_id && currentRate?.coverages
        )
      : null
    if (item) {
      const coverage = item?.coverages?.coverage?.[0] ?? item?.coverages?.coverage
      const termMonths = coverage?.term_months
      const termMiles = coverage?.term_miles
      const deductible = coverage?.deductibles?.deductible?.retail_price
      const termMonthsWithLabel = termMonths ? `${termMonths} mo` : ''
      const termMilesWithLabel = termMiles
        ? `${termMiles?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
          })} mi`
        : ''
      const deductibleWithLabel = deductible
        ? `${defaultCurrency}${(deductible ?? 0)?.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })}`
        : '-'
      return {
        termMonths: termMonthsWithLabel,
        termMiles: termMilesWithLabel,
        deductible: deductibleWithLabel
      }
    }
    return {}
  }

  useEffect(() => {
    const newBoxData = fniBundles?.map((bundle: any) => {
      let addedItems = Array.isArray(fniBundleChanges?.[bundle?.id]?.added)
        ? fniBundleChanges[bundle?.id]?.added
        : []

      let bundleItems =
        bundle?.financial_products?.filter((product: any) => product?.bundle_fp_status) || []

      const removedBundleItems = fniBundleChanges?.[bundle?.id]?.removed || []

      let transformedBundleItems = bundleItems
        ?.map((product: any) => ({
          ...(product?.financial_insurance_product ?? product),
          bundle_id: product?.bundle_id,
          bundle_markup: bundle?.markup
        }))
        ?.filter((bundeItem: any) => !removedBundleItems?.includes(bundeItem?.product_id))

      const filteredBundleItems = transformedBundleItems?.filter((bundleItem: any) => {
        const matchingSelectedItems = selectedFinanceItems?.filter(
          (selectedItem: any) => selectedItem?.bundle_id === bundleItem?.bundle_id
        )

        if (matchingSelectedItems?.length === 0) {
          return true
        }

        return matchingSelectedItems?.some(
          (selectedItem: any) =>
            selectedItem?.product_id === bundleItem?.product_id ||
            bundleItem?.id === (selectedItem?.financial_insurance_id ?? selectedItem?.id)
        )
      })

      transformedBundleItems = [...filteredBundleItems, ...addedItems]

      transformedBundleItems = applyRetailPrice(
        transformedBundleItems,
        isFinanceType ? financeProductRates : leaseProductRates
      )

      const totalPrice = transformedBundleItems?.reduce((sum: number, product: any) => {
        const price = Number(
          parseFloat(
            (fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj?.price ?? product?.price) ||
              0
          )?.toFixed(2)
        )
        return sum + price
      }, 0)

      return {
        id: bundle?.id,
        label: bundle?.bundle_name,
        price: `${defaultCurrency}${(totalPrice ?? 0)?.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })}`,
        items:
          transformedBundleItems?.map((product: any) => {
            const id = product?.financial_insurance_id ?? product?.id
            const matchedProduct = allFniProducts?.find((fniProduct: any) => fniProduct?.id === id)
            const productCoverage = getCoverage(matchedProduct)
            const price =
              fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj?.price ?? product?.price
            return {
              ...matchedProduct,
              ...product,
              ...fniBundleChanges?.[bundle?.id]?.[product?.product_id]?.obj,
              title: product?.product_name,
              price: parseFloat(price || 0),
              duration: product?.term_months ?? matchedProduct?.term_months,
              mileage: product?.term_miles ?? matchedProduct?.term_miles,
              cost: productCoverage?.deductible
            }
          }) || []
      }
    })

    setBoxData(newBoxData)
  }, [
    fniBundles,
    selectedBundle,
    allFniProducts,
    isFinanceType,
    financeProductRates,
    leaseProductRates,
    fniBundleChanges,
    selectedBundleIndex,
    selectedFinanceItems,
    selectedLeaseItems
  ])

  const selectedItemIds = (items: any[]) =>
    items?.filter((item: any) => !item?.bundle_id).map((item: any) => item?.id)

  const disabledBundles = fniBundles
    ?.filter((bundle: any) =>
      bundle?.financial_products?.some((product: any) =>
        (isFinanceType
          ? selectedItemIds(selectedFinanceItems)
          : selectedItemIds(selectedLeaseItems)
        )?.includes(product?.financial_product_id)
      )
    )
    ?.map((bundle: any) => bundle?.id)

  const handleBundleSelection = (index: number) => {
    if (isFinanceType) {
      setSelectedFinanceItems((prevItems: any) => prevItems.filter((item: any) => !item?.bundle_id))
    } else {
      setSelectedLeaseItems((prevItems: any) => prevItems.filter((item: any) => !item?.bundle_id))
    }

    if (selectedBundleIndex !== index) {
      setFniBundleChanges((prevState: any) => {
        const updatedState = { ...prevState }
        const bundleId = fniBundles?.[selectedBundleIndex]?.id

        if (selectedBundleIndex !== index) {
          if (bundleId) {
            delete updatedState[bundleId]
          }
        }

        return updatedState
      })
    }
    setSelectedBundleIndex(selectedBundleIndex === index ? null : index)
  }

  const handleAddItem = (item: any) => {
    const bundleId = item?.bundle_id
    const productId = item?.product_id
    setFniBundleChanges((prevState: any) => {
      const updatedState = { ...prevState }
      if (!updatedState[bundleId]) {
        updatedState[bundleId] = { removed: [], added: [] }
      }

      if (!updatedState?.[bundleId]?.added?.includes(productId)) {
        updatedState?.[bundleId]?.added?.push(item)
      }

      updatedState[bundleId].removed = updatedState?.[bundleId]?.removed?.filter(
        (id: any) => id !== productId
      )

      return updatedState
    })
  }

  return (
    <Grid theme={theme} container columnSpacing={2} rowSpacing={1} mt={1}>
      {boxData?.map((bundle: any, index: number) => (
        <Grid theme={theme} item sm={4} xs={12} key={index}>
          <ProductsBundleWrap className="bundles-wrap" theme={theme}>
            <Box
              theme={theme}
              className={`bundle-box ${selectedBundleIndex === index ? 'selected-box' : ''}`}
            >
              <Box
                theme={theme}
                className="bundle-header"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
                mb={2}
              >
                <Box theme={theme}>
                  <Typography theme={theme} variant="subtitle2" component="p">
                    {bundle?.label}
                  </Typography>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="p"
                    display={'flex'}
                    flexWrap={'wrap'}
                    gap={0.5}
                  >
                    <span className="title-sm">{bundle.price}</span>
                  </Typography>
                </Box>
                <Button
                  theme={theme}
                  disabled={disabledBundles?.includes(bundle?.id)}
                  text={selectedBundleIndex === index ? 'Selected' : 'Select'}
                  primary={selectedBundleIndex === index}
                  secondary={selectedBundleIndex !== index}
                  onClick={() => handleBundleSelection(index)}
                />
              </Box>
              <ProductsBundleList
                defaultCurrency={defaultCurrency}
                bundle={bundle}
                items={bundle?.items}
                isSelected={selectedBundleIndex === index}
                selectedLeaseItems={selectedLeaseItems}
                setSelectedLeaseItems={setSelectedLeaseItems}
                selectedFinanceItems={selectedFinanceItems}
                setSelectedFinanceItems={setSelectedFinanceItems}
                dealerInfo={dealerInfo}
                setFniBundleChanges={setFniBundleChanges}
                isBundleDisabled={disabledBundles?.includes(bundle?.id)}
              />
              <Box theme={theme} className="add-list">
                <Menu
                  theme={theme}
                  disablePortal
                  options={[]}
                  handleOptionClick={() => {}}
                  setMenuAnchorEl={setFniMenuAchorEl}
                  menuAnchorEl={fniMenuAnchorEl}
                  customChildren={
                    <>
                      <Autocomplete
                        theme={theme}
                        freeSolo={false}
                        disablePortal
                        endAdornment={<Icon name="SearchIcon" />}
                        open={true}
                        searchMatchFrom="any"
                        items={availableFniItems || []}
                        getOptionLabel={(items: any) => items?.product_name || '-'}
                        onInputChange={() => {}}
                        onChange={(_e: any, value: any) => {
                          const selectedBundle = fniBundles?.[selectedBundleIndex]
                          const fniToAddInBundle = applyRetailPrice(
                            [
                              {
                                ...value,
                                bundle_id: selectedBundle?.id,
                                bundle_markup: selectedBundle?.markup
                              }
                            ],
                            isFinanceType ? financeProductRates : leaseProductRates
                          )
                          handleAddItem(fniToAddInBundle?.[0])
                          setFniMenuAchorEl(null)
                        }}
                        sx={{ mb: 2 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Name"
                            placeholder="Search..."
                            variant="outlined"
                            defaultValue={''}
                            fullWidth
                          />
                        )}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            <span>{item.product_name || '-'}</span>
                          </li>
                        )}
                      />
                    </>
                  }
                  render={(onMenuSelection) => (
                    <Button
                      theme={theme}
                      className="dashed-btn"
                      secondary
                      iconText={<Icon name="AddIcon" />}
                      onClick={onMenuSelection}
                      disabled={!availableFniItems || availableFniItems?.length === 0}
                    />
                  )}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                />
              </Box>
            </Box>
          </ProductsBundleWrap>
        </Grid>
      ))}
    </Grid>
  )
}

export default ProductsBundle
