import {
  Box,
  Button,
  Dialog,
  Grid,
  Icon,
  Menu,
  Select,
  Textarea,
  Typography,
  Tooltip
} from "@ntpkunity/controls";
import { Skeleton, useTheme } from "@mui/material";
import { OrdersDetailsWrap } from "../orderDetailPageHeaderStyle";
import { useEffect, useState } from "react";
import {
  useCustomerOrderData,
  useUpdateOrderStatus,
} from "common/hooks/useCustomerOrderData";
import { useParams } from "react-router";
import {
  InvoiceDisplayStatus,
  OrderStatus,
  SubscriptionButton,
} from "enums";
import {
  useGetContractByRefercnceId,
  useUpdateInternalSubscription,
} from "common/hooks/useSubscription";
import { useStoreContext } from "store/storeContext";
import DisableLoader from "../../../../shared/assets/images/loader-disabled.gif";
import { SubscriptionStatus } from "enums/SubscriptionTypes";
import {getInternalAccessToken, utcToLocal } from "utilities/methods";
import { useGetConfiguredReasons } from "common/hooks/useConfigurationData";
import { Controller, useForm } from "react-hook-form";
import { CANCEL_REASON_UPDATE_MESSAGE, DEAL_NUMBER_UPDATE_MESSAGE ,dateFormatSearch,utcToLocalForHistoryFormat} from "common/constants";
import { ValidationMessages } from "enums/ValidationMessages";
import { useGetUpComingInvoice, useStripeInvoiceList } from "common/hooks/useHubexData";
import dayjs from "dayjs";
import DatePicker from "@shared/DatePicker/DatePicker";
export const OrderDetails = () => {
  const form = useForm<any>({});
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    setValue,
    watch,
    setError,
    clearErrors,
  } = form;
  const tokenInforamtion = getInternalAccessToken();
  const theme = useTheme();
  const params = useParams();
  const { actions } = useStoreContext();
  const userId = params?.reference_id;
  const order_reference_id = params?.order_reference_id;
  const { data: getCustomerOrderData, isLoading } =
    useCustomerOrderData(userId);
  const [orderData, setCustomerOrderData] = useState<any>();
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [selectedOrderVehicle, setSelectedOrderVehicle] = useState<any>();
  const [dealNumberDialogOpened, setDealNumberDialogOpened] = useState<boolean>(false);
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [reasonDialogOpened, setReasonUpdateOpened] = useState<boolean>(false);
  const [paymentDueDate, setPaymentDueDate] = useState();
  const [subscriptionDateDialogOpened, setSubscriptionDateUpdateOpened] =
    useState<boolean>(false);
  const [buttonText, setButtonText] = useState(undefined);
  const [vendorSubscriptionId, setVendorSubscriptionId] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [pastDuedays, setPastDueDays] = useState();
  const [amountDue, setAmountDue] = useState<any>();
  const [configureReason, setConfigureReason] = useState<
    { value: string; text: string }[]
  >([]);
  const { data: getConratctByRefernceId, isLoading: isContractLoading } =
    useGetContractByRefercnceId(
      params?.reference_id,
      params?.order_reference_id
    );

  const {
    mutate: updateInternalSubscription,
    isLoading: isUpdatingSubscription,
  } = useUpdateInternalSubscription();
  const { mutate: getPaymentDueDate, isLoading:paymentDueDateLoading } = useGetUpComingInvoice();
  const { mutate: updateOrderStatus, isLoading: saveButtonLoading } =
    useUpdateOrderStatus();
  const { mutate: getAllConfiguredReasons, isLoading: reasonLoading } =
    useGetConfiguredReasons();
  const {
    mutate: getStripeInvoices,
    isLoading: customerInvoiceSearchResultLoading,
  } = useStripeInvoiceList();

  useEffect(() => {
    if (getConratctByRefernceId) {
      if (!getValues("start_date")) {
        setValue("start_date", (dayjs(getConratctByRefernceId?.start_date && utcToLocal(getConratctByRefernceId?.start_date,utcToLocalForHistoryFormat)) as any));
      }
      const subscriptionId = getConratctByRefernceId?.vendor_subscription_id;
      getStripeInvoices(
        {
          "customer": getConratctByRefernceId?.customer?.stripe_customer_id,
          "subscription": subscriptionId,
          "meta_data": {"stripe_subscription_id": subscriptionId}
        },
        {
          onSuccess(response) {
            const openInvoices = response?.data?.filter(
              (invoice) =>
                invoice.invoice_status === InvoiceDisplayStatus.OPEN ||
                invoice.invoice_status === InvoiceDisplayStatus.PAST_DUE ||
                invoice.invoice_status === InvoiceDisplayStatus.PENDING
            );
            const amount_due = openInvoices?.reduce((sum, currentInvoice) => {
              return sum + currentInvoice?.due_amount;
            }, 0);
            setAmountDue(amount_due);
            const pastDueInvoices = response?.data?.filter(invoice => invoice.invoice_status === InvoiceDisplayStatus.PAST_DUE)
            const past_due_days = pastDueInvoices?.reduce((maxInvoice, currentInvoice) => {
                return currentInvoice?.past_due_days > maxInvoice?.past_due_days ? currentInvoice : maxInvoice;    
            }, pastDueInvoices?.[0])?.past_due_days
            setPastDueDays(past_due_days)
            
          },
          onError(error: any) {
            console.log("Error Occured", error);
          },
        }
      );
    }
  }, [getConratctByRefernceId]);

  useEffect(() => {
    getAllConfiguredReasons(
      {},
      {
        onSuccess: ({ data }) => {
          data = [
            ...data,
            { reason_name: "OTHER", reason_description: "Other" },
          ];
          setConfigureReason(
            data.map((item) => ({
              value: item?.reason_name,
              text: item?.reason_description,
            }))
          );
        },
      }
    );
  }, []);
  useEffect(() => {
    if (getConratctByRefernceId) {
      setIdentifier(getConratctByRefernceId?.identifier);
      setButtonText(
        getConratctByRefernceId?.subscription_status ===
          SubscriptionStatus.ACTIVE
          ? SubscriptionButton.PAUSE_SUBSCRIPTION
          : SubscriptionButton.RESUME_SUBSCRIPTION
      );
      setVendorSubscriptionId(getConratctByRefernceId?.vendor_subscription_id);
    }
  }, [getConratctByRefernceId]);

  useEffect(() => {
    if (getCustomerOrderData?.data) {
      setCustomerOrderData(getCustomerOrderData?.data?.orders);
    }
  }, [getCustomerOrderData?.data]);

  useEffect(() => {
    if (orderData) {
      setSelectedOrder(orderData?.find((f) => f.id === order_reference_id));
    }
  }, [orderData]);
  useEffect(() => {
    if (selectedOrder) {
      setSelectedOrderVehicle(
        selectedOrder?.orderItems?.find((f) => f.type === "vehicle")
      );
      setValue("reason", selectedOrder?.cancellationCode);
      setValue("cancelleation_detail", selectedOrder?.cancellationReason);
      setValue('deal_number', selectedOrder?.dealNumber)
    }
  }, [selectedOrder]);

  const handleUpdateSubscription = (status) => {
    updateInternalSubscription(
      {
        identifier: identifier,
        vendor_subscription_id: vendorSubscriptionId,
        subscription_status: status,
        updated_by: tokenInforamtion?.user_name,
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage:
              status === SubscriptionStatus.PAUSE
                ? "Subscription Paused Successfully"
                : "Payments Resumed Successfully",
            toastState: true,
          });
          setDialogOpened(false);
        },
        onError(error: any) {
          actions.setToast({
            toastMessage:
            error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
          setDialogOpened(false);
        },
      }
    );
  };

  const handleDoneEvent = () => {
    if (
      getValues("reason") == "OTHER" &&
      getValues("cancelleation_detail").valueOf()?.length > 100
    ) {
      setError("cancelleation_detail", {
        message: ValidationMessages.CANCELLATION_REASON_LENGTH,
      });
    } else {
      updateOrderStatus(
        {
          orderId: params?.order_reference_id,
          cancellationCode: getValues("reason")? getValues("reason") : undefined,
          cancellationReason:
            getValues("reason") == "OTHER"
              ? getValues("cancelleation_detail")
              : "",
          cancelledBy: tokenInforamtion?.user_name,
        },
        {
          onSuccess() {
            {
              setReasonUpdateOpened(false);
              setDealNumberDialogOpened(false);
            }
            reset();
            actions.setToast({
              toastMessage: CANCEL_REASON_UPDATE_MESSAGE,
              toastState: true,
            });
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: "error",
            });
            setReasonUpdateOpened(false);
            setDealNumberDialogOpened(false);
          },
        }
      );
    }
  };

  const handleDealNumber = () => {
    const dealerNumber = getValues("deal_number")
    if(dealerNumber?.valueOf()?.length > 25){
      setError("deal_number", {message: ValidationMessages.DEAL_NUMBER_LENGTH})
      actions.setToast({
        toastMessage: ValidationMessages.DEAL_NUMBER_LENGTH,
        toastState: true,
        variant: "error",
      });
      setDealNumberDialogOpened(false);
    } else {
      updateOrderStatus(
        {
          orderId: params?.order_reference_id,
                  dealNumber:getValues("deal_number") ? getValues("deal_number") : 0
        },
        {
          onSuccess() {
            {
              setDealNumberDialogOpened(false);
            }
                    reset()
            actions.setToast({
              toastMessage: DEAL_NUMBER_UPDATE_MESSAGE,
              toastState: true,
            });
          },
          onError(error: any) {
            actions.setToast({
              toastMessage: error.error.toString(),
              toastState: true,
              variant: "error",
            });
            setDealNumberDialogOpened(false);
          },
        }
      );
    }
  };

  const handleUpdateSubscriptionDate = () => {
    const currentTime = dayjs().tz(dayjs.tz.guess());
    updateInternalSubscription(
      {
        identifier: identifier,
        updated_by: tokenInforamtion?.user_name,
            start_date:  dayjs(getValues("start_date")).tz(dayjs.tz.guess()).set('hour', currentTime.hour()).set('minute', currentTime.minute()).set('second', currentTime.second()),
        end_date: new Date(getValues("end_date")),
      },
      {
        onSuccess() {
          actions.setToast({
            toastMessage: "Subscription Start Date and End Date updated",
            toastState: true,
          });
          setSubscriptionDateUpdateOpened(false);
        },
        onError(error: any) {
          actions.setToast({
                    toastMessage:
                      error?.detail?.message?.toString(),
            toastState: true,
            variant: "error",
          });
          setSubscriptionDateUpdateOpened(false);
        },
      }
    );
  };
  useEffect(() => {
    if (getConratctByRefernceId) {
      const payload = {
        subscription: getConratctByRefernceId?.vendor_subscription_id
      };
      getPaymentDueDate(
        { payload },
        {
          onSuccess(data) {
            setPaymentDueDate(data?.next_payment_attempt)
          }
        }
      );
    }
  }, [getConratctByRefernceId]);

const eventCompletedOn = (selectedOrder?.events?.find(item => item?.type === "PICKUP")?.completedOn)
const cancelReasonDescription = configureReason && configureReason?.find(item => item?.value === selectedOrder?.cancellationCode && selectedOrder?.cancellationCode);
const cancelReasonText = cancelReasonDescription ? cancelReasonDescription?.text : null;
  return (
    <>
      <OrdersDetailsWrap>
        <Grid
          theme={theme}
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 3.5 }}
        >
          {/* {useHasPermissions(
            ccpPermissions.FIELDS.VIEW_SUBSCRIPTION_PAUSE_RESUME_BUTTON,
            PermissionTypes.VIEW
          ) ? (
            <Grid item xs={12} sm={12} md={12} lg={12} theme={theme}>
              {isLoading  ? (
                <Skeleton width={"100%"} height={20} />
              ) : (
                <Box theme={theme} textAlign={"right"}>
                  {getConratctByRefernceId?.subscription_status ===
                  SubscriptionStatus.ACTIVE ? (
                    <Button
                      theme={theme}
                      secondary
                      text={SubscriptionButton.PAUSE_SUBSCRIPTION}
                      onClick={() => {
                        setDialogOpened(true);
                      }}
                      disabled={
                        getConratctByRefernceId?.invocation_type === "flag"
                      }
                    />
                  ) : getConratctByRefernceId?.subscription_status ===
                    SubscriptionStatus.PAUSE ? (
                    <Button
                      theme={theme}
                      secondary
                      text={SubscriptionButton.RESUME_SUBSCRIPTION}
                      onClick={() => {
                        setDialogOpened(true);
                      }}
                      disabled={
                        getConratctByRefernceId?.invocation_type === "flag"
                      }
                    />
                  ) : null}
                </Box>
              )}
            </Grid>
          ) : null} */}
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <>
                <Box className="label" theme={theme}>
                  <Typography
                    variant="caption"
                    component="span"
                    rowGap={5}
                    theme={theme}
                  >
                    Order ID/Number:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="h4"
                    rowGap={5}
                    theme={theme}
                  >
                    {selectedOrder && selectedOrder?.id}
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Order Type:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {selectedOrder && selectedOrder?.type}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Order Status:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {selectedOrder && selectedOrder?.status}
                </Typography>
              </Box>
            )}
          </Grid>
          {isLoading ? (
            <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
              <Skeleton width={"100%"} height={20} />
            </Grid>
          ) : (
            selectedOrder?.status === OrderStatus.CANCELED && (
              <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
                <Box theme={theme} sx={{ display: "flex" }}>
                  <Box className="label" theme={theme}>
                    <Typography
                      variant="caption"
                      component="span"
                      rowGap={5}
                      theme={theme}
                    >
                      Cancellation Reason
                    </Typography>
                    <Typography
                      variant="body2"
                      component="h4"
                      rowGap={5}
                      theme={theme}
                      className="text-truncated"
                    >
                      {cancelReasonText ? cancelReasonText : "-"}
                    </Typography>
                  </Box>
                  <Box theme={theme}>
                    <Menu
                      theme={theme}
                      options={[
                        {
                          optionText: "Edit",
                          optionValue: "edit",
                        },
                      ]}
                      handleOptionClick={(_event, _key, value) => {
                        switch (value) {
                          case "edit":
                            setReasonUpdateOpened(true);
                            break;
                        }
                      }}
                      render={(onMenuSelection) => (
                        <Button
                          theme={theme}
                          className="btn-menu"
                          iconText={<Icon name="MoreIcon" />}
                          onClick={onMenuSelection}
                        />
                      )}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            )
          )}
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Term:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {selectedOrder && selectedOrder?.metadata?.term}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Miles Per Month:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {(selectedOrder?.metadata?.milesPerMonth /selectedOrder?.metadata.term).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Loaded Monthly Price:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {selectedOrder &&
                  selectedOrder?.monthlyPayment +
                    selectedOrder?.monthlyPaymentFee +
                    selectedOrder?.monthlyPaymentTax >
                    0
                    ? (
                        (selectedOrder?.monthlyPayment +
                          selectedOrder?.monthlyPaymentFee +
                          selectedOrder?.monthlyPaymentTax)
                      )?.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })
                    : "-"}
                </Typography>
              </Box>
            )}
          </Grid>
          {selectedOrder?.status === OrderStatus.COMPLETED &&
          (getConratctByRefernceId?.subscription_status ===
            SubscriptionStatus.ACTIVE ||
            getConratctByRefernceId?.subscription_status ===
              SubscriptionStatus.DRAFT) &&
          dayjs()?.isBefore(dayjs(eventCompletedOn)?.add(46, "day")) ? (
            <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
              {isLoading ? (
                <Skeleton width={"100%"} height={20} />
              ) : (
                <Box sx={{ width: "65%" }} theme={theme}>
                  <Controller
                    name="start_date"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        theme={theme}
                        label={"Subscription Start Date"}
                        value={value? value: (dayjs(getConratctByRefernceId?.start_date && utcToLocal(getConratctByRefernceId?.start_date,utcToLocalForHistoryFormat)) as any)
                        }
                        onDateChange={(e: Date) => {
                          onChange(e);
                          setSubscriptionDateUpdateOpened(true);
                          const convertValue = new Date(e);
                          const endDate = convertValue?.setMonth(
                            convertValue?.getMonth() +
                              (selectedOrderVehicle &&
                                selectedOrderVehicle?.metadata?.term)
                          );
                          setValue("end_date", endDate);
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>
          ) : getConratctByRefernceId?.subscription_status ===
              SubscriptionStatus.ACTIVE ||
            getConratctByRefernceId?.subscription_status ===
              SubscriptionStatus.DRAFT ? (
            <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
              {isLoading ? (
                <Skeleton width={"100%"} height={20} />
              ) : (
                <Box sx={{ width: "65%" }} theme={theme}>
                  <Controller
                    name="start_date"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        theme={theme}
                        label={"Subscription Start Date"}
                        value={
                          value
                            ? value
                            : (dayjs(
                                getConratctByRefernceId?.start_date
                              ) as any)
                        }
                        onDateChange={(e: Date) => {
                          onChange(e);
                          setSubscriptionDateUpdateOpened(true);
                          const convertValue = new Date(e);
                          const endDate = convertValue?.setMonth(
                            convertValue?.getMonth() +
                              (selectedOrderVehicle &&
                                selectedOrderVehicle?.metadata?.term)
                          );
                          setValue("end_date", endDate);
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
              {isLoading ? (
                <Skeleton width={"100%"} height={20} />
              ) : (
                <Box className="label" theme={theme}>
                  <Typography
                    variant="caption"
                    component="span"
                    rowGap={5}
                    theme={theme}
                  >
                    Subscription Start Date:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="h4"
                    rowGap={5}
                    theme={theme}
                  >
                       {getConratctByRefernceId?.start_date
                      ? utcToLocal(
                          getConratctByRefernceId?.start_date,
                          dateFormatSearch
                        )
                      : "-"}
                  </Typography>
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Subscription End Date:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                     {getConratctByRefernceId?.end_date
                      ? utcToLocal(
                          getConratctByRefernceId?.end_date,
                          dateFormatSearch
                        )
                      : "-"}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading || paymentDueDateLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Payment Due Date:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {paymentDueDate
                            ? utcToLocal(
                              new Date(
                                paymentDueDate * 1000
                              )?.toISOString(),
                              dateFormatSearch
                            )
                            : "-"}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Odometer:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {selectedOrder &&
                  selectedOrder?.metadata?.odometerAtOrderCreation}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Allowed Term Miles:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {getConratctByRefernceId?.allowed_miles
                    ? getConratctByRefernceId?.allowed_miles
                    : "-"}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Dealership:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {(selectedOrder && selectedOrder?.fulfillment?.dealerName) ||
                    "-"}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Upfront Payment:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {selectedOrder &&
                  selectedOrder?.metadata?.downPayment
                    ? (
                      selectedOrder?.metadata?.downPayment
                      )?.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })
                    : 0}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Security Deposit Amount:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  
                  {selectedOrder &&
                  selectedOrder?.metadata?.securityDeposit
                    ? (
                      selectedOrder?.metadata?.securityDeposit 
                      )?.toLocaleString("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      })
                    : "-"}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {customerInvoiceSearchResultLoading || isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Box theme={theme} className="tooltip-wrap">
                  <Typography
                    className="label-title"
                    variant="caption"
                    component="span"
                    rowGap={5}
                    theme={theme}
                  >
                    Amount Due:
                  </Typography>
                  <Box theme={theme} className="tooltip">
                    <Tooltip
                      theme={theme}
                      title={"This amount includes all unpaid open invoices"}
                      placement="bottom"
                    >
                      <Icon name="InfoIcon" />
                    </Tooltip>
                  </Box>
                </Box>
                {amountDue && amountDue > 0
                  ? (amountDue)?.toLocaleString("en-GB", {
                      style: "currency",
                      currency: "GBP",
                    })
                  : "-"}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {customerInvoiceSearchResultLoading || isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Past Due Days:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {pastDuedays ?? 0}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isContractLoading || isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Billing Status:
                </Typography>
                <Typography
                  variant="body2"
                  component="h4"
                  rowGap={5}
                  theme={theme}
                >
                  {getConratctByRefernceId?.billing_status || "-"}
                </Typography>
              </Box>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={4} theme={theme}>
            {isContractLoading || isLoading ? (
              <Skeleton width={"100%"} height={20} />
            ) : (
              <Box className="label input-field" theme={theme}>
                <Typography
                  variant="caption"
                  component="span"
                  rowGap={5}
                  theme={theme}
                >
                  Deal Number:
                </Typography>
                <Controller
                  name="deal_number"
                  control={control}
                  defaultValue={""}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      theme={theme}
                      value={value ?? "-"}
                      fullWidth
                      placeholder="0000"
                      label=""
                      type="text"
                      onChange={(e) => {
                        const updatedValue = e?.replace(/-/g, "");
                        onChange(updatedValue);
                        clearErrors("deal_number");
                      }}
                      onBlur={(e) => {
                        if (
                          e?.target?.value !== selectedOrder?.dealNumber &&
                          e?.target?.value !== "" &&
                          e?.target?.value !== "-"
                        ) {
                          setDealNumberDialogOpened(true);
                        }
                      }}
                      error={errors?.deal_number?.message?.toString()}
                    />
                  )}
                />
              </Box>
            )}
          </Grid> */}
        </Grid>
      </OrdersDetailsWrap>
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={dialogOpened}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="DialogAlertIcon" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  {`Are you sure you want to ${
                    buttonText === SubscriptionButton.PAUSE_SUBSCRIPTION
                      ? "pause"
                      : "resume"
                  } subscription?`}
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  {buttonText === SubscriptionButton.PAUSE_SUBSCRIPTION
                    ? "Subscription will be paused for an indefinite period (even after the subscription end date) and all invoices will be created as Draft during pause period"
                    : "Subscription will be resumed and all pending invoices will be sent to customer for payment"}
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setDialogOpened(false);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  text="Save"
                  disabled={isUpdatingSubscription}
                  onClick={() =>
                    buttonText === SubscriptionButton.PAUSE_SUBSCRIPTION
                      ? handleUpdateSubscription(SubscriptionStatus.PAUSE)
                      : handleUpdateSubscription(SubscriptionStatus.RESUME)
                  }
                  endIcon={
                    isUpdatingSubscription && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
      <Dialog
        theme={theme}
        title="Cancelation Reason"
        open={reasonDialogOpened}
        disablePortal={true}
        children={
          <>
            <Box theme={theme}>
              <Box theme={theme} mt={1}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="subtitle2"
                  component="h4"
                >
                  Please specify the reason of Cancelation.
                </Typography>
                {reasonLoading ? (
                  <Skeleton width={"100%"} height={20} />
                ) : (
                  <Controller
                    name="reason"
                    control={control}
                    defaultValue={""}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        theme={theme}
                        label={"Reason"}
                        items={configureReason}
                        placeholder={"Select"}
                        disablePortal={false}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        selectError={errors?.reason?.message?.toString()}
                      />
                    )}
                  />
                )}
                <Grid theme={theme} item xs={12}>
                  {watch("reason") == "OTHER" && (
                    <Controller
                      name="cancelleation_detail"
                      control={control}
                      defaultValue={""}
                      rules={{
                        maxLength: {
                          value: 100,
                          message:
                            ValidationMessages.CANCELLATION_REASON_LENGTH,
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Textarea
                          type="text"
                          theme={theme}
                          id="CancellationReason"
                          label="Cancellation Reason"
                          fullWidth
                          placeholder="Type your Comment here..."
                          rows={5}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            clearErrors("cancelleation_detail");
                          }}
                          error={errors?.cancelleation_detail?.message?.toString()}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Box>
              <Box theme={theme} mt={3}>
                <hr />
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  onClick={() => {
                    setReasonUpdateOpened(false);
                  }}
                  text={"Cancel"}
                ></Button>
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  theme={theme}
                  primary
                  disabled={saveButtonLoading}
                  endIcon={
                    saveButtonLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    )
                  }
                  onClick={() => {
                    handleDoneEvent();
                  }}
                  text={"Submit"}
                ></Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <Dialog
        theme={theme}
        size="xs"
        variant="confirmation"
        open={subscriptionDateDialogOpened || dealNumberDialogOpened}
        children={
          <>
            <Box theme={theme} textAlign="center">
              <Box theme={theme} className="dialog-icon">
                <Icon name="ErrorTriangle" />
              </Box>
              <Box theme={theme} mt={4}>
                <Typography
                  theme={theme}
                  mb={2}
                  className="content-title"
                  variant="h2"
                  component="h2"
                >
                  {subscriptionDateDialogOpened
                    ? "Are you sure to update start date and end date of the subscription."
                    : dealNumberDialogOpened
                    ? "Are you sure to update Deal Number."
                    : ""}
                </Typography>
                <Typography
                  theme={theme}
                  className="text-muted"
                  variant="body2"
                  component="span"
                >
                  {!dealNumberDialogOpened &&
                    "Once saved, you cannot undo this action."}
                </Typography>
              </Box>
            </Box>
          </>
        }
        customFooter={
          <>
            <Grid container theme={theme} spacing={2}>
              <Grid theme={theme} item xs={6} textAlign={"right"}>
                <Button
                  theme={theme}
                  secondary
                  text="Cancel"
                  onClick={() => {
                    setSubscriptionDateUpdateOpened(false);
                    setDealNumberDialogOpened(false);
                    setValue(
                      "start_date",
                      dayjs(getConratctByRefernceId?.start_date)
                    );
                    setValue("deal_number", selectedOrder?.dealNumber);
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={6}>
                <Button
                  disabled={isUpdatingSubscription || saveButtonLoading}
                  theme={theme}
                  primary
                  text="Save"
                  onClick={() => {
                    subscriptionDateDialogOpened
                      ? handleUpdateSubscriptionDate()
                      : handleDealNumber();
                  }}
                  endIcon={
                    isUpdatingSubscription ||
                    (saveButtonLoading && (
                      <img src={DisableLoader} alt="Loader" />
                    ))
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
};
