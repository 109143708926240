import { FC } from 'react'
import { useTheme } from '@mui/material'
import { LayoutWithSideNav } from '@styles/layout'
import { PageHeader } from '@components'
import { Grid, Typography } from '@ntpkunity/controls'
import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { FullCalendarWrap, PageContent } from './calendar-page.style'

const getCurrentWeekDate = (): string => {
  const now = new Date()

  // Helper function to get a random integer in a range
  const getRandomInt = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min

  // Calculate the start of the current week (Monday)
  const currentDay = now.getDay() // Sunday = 0, Monday = 1, ..., Saturday = 6
  const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay
  const currentWeekMonday = new Date(now)
  currentWeekMonday.setDate(now.getDate() + diffToMonday)
  currentWeekMonday.setHours(0, 0, 0, 0) // Start of Monday, 12:00 AM

  // Calculate the start of the next week (Monday)
  const nextWeekMonday = new Date(currentWeekMonday)
  nextWeekMonday.setDate(currentWeekMonday.getDate() + 7)

  // Generate a random week: 0 for current week, 1 for next week
  const randomWeek = getRandomInt(0, 1)

  // Determine the starting point (current week or next week)
  const weekStart = randomWeek === 0 ? currentWeekMonday : nextWeekMonday

  // Randomly pick a day (Monday to Friday, 0 to 4)
  const randomDayOffset = getRandomInt(0, 4)
  const randomDate = new Date(weekStart)
  randomDate.setDate(weekStart.getDate() + randomDayOffset)

  // Randomly pick a time (10:00 AM to 5:00 PM)
  const randomHour = getRandomInt(10, 16) // 16 because 5:00 PM is the last hour
  const randomMinute = getRandomInt(0, 59)
  randomDate.setHours(randomHour, randomMinute, 0, 0)

  return randomDate.toISOString()
}

const events = [
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) },
  { title: 'Trade in Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) },
  { title: 'Trade in Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) },
  { title: 'Trade in Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) },
  { title: 'Trade in Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) },
  { title: 'Trade in Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) },
  { title: 'Trade in Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) },
  { title: 'Trade in Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Delivery schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Pick-up', start: new Date(getCurrentWeekDate()) },
  { title: 'Test Drive Schedule', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Inspection', start: new Date(getCurrentWeekDate()) },
  { title: 'Vehicle Delivery Checklist', start: new Date(getCurrentWeekDate()) }
]

const renderEventContent = (eventInfo: any) => {
  return (
    <>
      <p className="fw-medium">{eventInfo.event.title}</p>
      <small className="text-muted text-uppercase">{eventInfo.timeText}</small>
    </>
  )
}

export const Calendar: FC = () => {
  const theme = useTheme()
  return (
    <LayoutWithSideNav theme={theme}>
      <PageContent theme={theme} className="page-content" pb={3}>
        <PageHeader
          className="main-page-header border-bottom"
          CalendarComponent
          theme={theme}
          container
          item
          spacing={2}
        >
          <Grid theme={theme} container spacing={2}>
            <Grid theme={theme} item lg={7} md={6} sm={12} xs={12}>
              <Typography theme={theme} variant="h2" component="h2">
                Calendar
              </Typography>
            </Grid>
          </Grid>
        </PageHeader>
        <FullCalendarWrap theme={theme} className="full-calendar-wrap">
          <FullCalendar
            plugins={[timeGridPlugin]}
            initialView="timeGridWeek"
            weekends={false}
            events={events}
            allDaySlot={false}
            eventContent={renderEventContent}
          />
        </FullCalendarWrap>
      </PageContent>
    </LayoutWithSideNav>
  )
}
