import { Dialog } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import ChooseAssetImage from "../../../../public/assets/images/search-inventory.png";

const ChooseAssetDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  return (
    <Dialog
      variant="full-window"
      theme={theme}
      onCloseDialog={() => {
        setOpen(false);
      }}
      open={open}
      disablePortal
      noFooter
    >
      <img src={ChooseAssetImage} alt="choose-asset" className="w-100" />
    </Dialog>
  );
};

export default ChooseAssetDialog;
