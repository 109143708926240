export const APP_ROUTES = {
  BASE_URL: '/lane/',
  CREATE_ORDER: '/lane/create-deal/:vin/:customer_reference_id?' /* pages/vehicle-selection.tsx */,
  CUSTOMER_DETAILS: '/lane/customer-details' /* pages/customer-details.tsx */,
  QUOTATION: '/lane/quotation' /* pages/quotation.tsx */,
  ORDER_SUMMARY: '/lane/deal-summary/:reference_id?' /* pages/order-summary.tsx */,
  LOGIN: '/lane' /* pages/login.tsx */,
  CHANGE_PASSWORD: '/lane/change-password' /* pages/login.tsx */,
  WORKQUEUE: '/lane/workqueue' /* pages/workqueue.tsx */,
  ORDER_STIPULATIONS: '/lane/deal-stipulation',
  INVENTORY_MANAGEMENT: '/lane/inventory-management' /* pages/inventory-management.tsx */,
  AddOns: '/lane/dealer-add-ons' /*pages/dealer-add-ons.tsx */,
  DEALER_CONFIGURATIONS: '/lane/dealer-configurations' /*pages/dealer-configurations.tsx */,
  FINANCIAL_INSURANCE: '/lane/financial-insurance' /*pages/financial-insurance-product.tsx */,
  CREATE_ORDER_QUOTATION:
    '/lane/create-deal-ui/:vin/:customer_reference_id?/:reference_id?/:reschedule_popup?' /*pages/create-order-ui.tsx */,
  DEALER_WORK_QUEUE: '/lane/dealer/workqueue' /*pages/dealer-work-queue.tsx */,
  DEALER_WORK_QUEUE_VEHICLE_AVAILABLE:
    '/lane/dealer/work-queue/vehicle-check/:id' /*pages/dealer-work-queue.tsx */,
  IN_PROGRESS: '/lane/dealer/in-progress' /* pages/in-progress-ui.tsx */,
  DEALER_GET_READY_CHECKLIST:
    '/lane/dealer/work-queue/checklist/:id' /*pages/dealer-vehicle-ready-checklist.tsx */,
  DEALER_VEHICLE_SCHEDULE_PICKUP:
    '/lane/dealer/work-queue/schedule-pickup/:id' /*pages/dealer-vehicle-schedule-pickup.tsx */,
  DEALER_VEHICLE_RETURN:
    '/lane/dealer/workqueue/vehicle-return/:id/:identifier' /*pages/dealer-vehicle-return.tsx */,
  NEW_HOME_PAGE: process.env.UNITY_FE_BASE_URL + `/market-place`,
  ALLOWED_PRODUCTS: process.env.UNITY_FE_BASE_URL + '/market-place/allowed-products',
  SSO_LOGIN: '/lane/sso-login',
  ORDER_APPROVED: '/lane/deal-approved/:reference_id' /* pages/order-summary.tsx */,
  ORDER_CANCELLED: '/lane/deal-cancelled/:reference_id' /* pages/order-summary.tsx */,
  ORDER_REVIEW: '/lane/deal-review/:reference_id/:customer_reference_id/:vin?',
  QUOTE_COMPARISON: '/lane/quote-comparison/:customer_email/:reference_id',
  FEEDBACK: '/lane/submit-feedback',
  MANAGE_USERS: '/lane/manage-users',
  APP_USER_PROFILE: '/market-place/app-user-profile',
  USER_PROFILE: '/lane/user-profile',
  DASHBOARD: '/lane/product-analytics',
  NOTFOUND: '/lane/not-found',
  DASHBOARDIMAGE: '/lane/business-analytics',
  DEALERSHIP_PERFORMANCE: '/lane/dealership-performance',
  BMW_DASHBOARD_OF_MANHATTAN: '/lane/dealer-performance',
  Customer_Journey_Analytics: '/lane/customer-journey-analytics',
  COBROWSING: '/lane/co-browsing' /* pages/workqueue.tsx */,
  CANCELLED_ORDERS: '/lane/dealer/work-queue/deal-cancelled/:id',
  COMPLETED_ORDERS: '/lane/dealer/work-queue/deal-completed/:id',
  LEAD_SUMMARY: '/lane/home/lead-summary',
  TESTINGPAGE: '/lane/testing-page',
  DESKING: '/lane/desking',
  UNAUTHORIZED: '/lane/unauthorized',
  HOME: '/lane/home',

  LANE_DASHBOARD: '/lane/dashboard',
  CALENDAR: '/lane/calendar',
  CONVERSATION: '/lane/conversation',
  ANALYTICS: '/lane/analytics',
  LEAD_SUMMARY_STATIC_ALEX: '/lane/lead-summary/alex',
  LEAD_SUMMARY_STATIC_HALES: '/lane/lead-summary/hales',
  LEAD_SUMMARY_STATIC_RAVN: '/lane/lead-summary/ravn',
  LEAD_SUMMARY_STATIC_SHANE: '/lane/lead-summary/shane',
  LEAD_SUMMARY_STATIC_WATSON: '/lane/lead-summary/watson'
}
