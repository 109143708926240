import { FC, useState } from "react";
import { styled, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DatePicker,
  Grid,
  IBoxProps,
  Icon,
  Input,
  Select,
  Switch,
  Typography,
} from "@ntpkunity/controls";
import { Controller, UseFieldArrayRemove } from "react-hook-form";
import { ValidationMessages, DialogMessages } from "Enums";
import { ConfirmationDialog } from "@sharedComponents";

const HolidayRow: FC<{
  form: any;
  dealer_code: string;
  index: number;
  remove: UseFieldArrayRemove;
}> = ({ form, dealer_code, index, remove }) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = form;
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const onDeleteConfirm = (index: any) => {
    remove(index);
  };
  return (
    <>
      <Box theme={theme} className="hd-row">
        <Box theme={theme} className="hd-col">
          <Controller
            name={`holidayData.${index}.holiday_purpose`}
            control={control}
            defaultValue={""}
            rules={{
              required: ValidationMessages.HOLIDAY_PURPOSE_REQUIRED,
              validate: (value) =>
                value.trim() !== "" ||
                ValidationMessages.HOLIDAY_PURPOSE_REQUIRED,
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                theme={theme}
                fullWidth
                placeholder={"Type here.."}
                label={"Purpose"}
                type="text"
                value={value?.trimStart()}
                onChange={onChange}
                error={errors?.holiday_purpose?.message}
              />
            )}
          />
        </Box>
        <Box theme={theme} className="hd-col">
          <Controller
            name={`holidayData.${index}.holiday_occurance`}
            control={control}
            defaultValue={""}
            rules={{
              required: ValidationMessages.HOLIDAY_OCCURANCE_REQUIRED,
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                theme={theme}
                label={"Occurance"}
                placeholder={"Select"}
                items={[
                  { text: "Yearly ", value: "Yearly" },
                  { text: "One-Time", value: "One-Time" },
                ]}
                value={value ?? []}
                onChange={onChange}
                selectError={errors?.holiday_occurance?.message}
              />
            )}
          />
        </Box>
        <Box theme={theme} className="hd-col">
          {/* <DatePicker theme={theme} /> */}
          <Controller
            name={`holidayData.${index}.holiday_date`}
            control={control}
            defaultValue={""}
            rules={{
              required: ValidationMessages.HOLIDAY_DATE_REQUIRED,
            }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                theme={theme}
                label={"Date"}
                value={value ?? ""}
                disablePast
                onChange={(e: Date) => {
                  if (e.getFullYear() !== 1969 && e.getFullYear() !== 1970) {
                    onChange(e);
                  }
                }}
                onKeyDown={(e: any) => e.preventDefault()}
                error={errors?.returns?.holiday_date?.message}
              />
            )}
          />
        </Box>
        <Box theme={theme} className="hd-col">
          <Button
            theme={theme}
            secondary
            fullWidth
            onClick={(e) => {
              setConfirmationPopupState(true);
            }}
            iconText={<Icon name="DeleteIcon" />}
          />
        </Box>
      </Box>
      <ConfirmationDialog
        openPopUp={confirmationPopupState}
        onConfirm={() => onDeleteConfirm(index)}
        setPopUpState={setConfirmationPopupState}
        confirmationTitle={DialogMessages.confirmationTitle}
        confirmationText={DialogMessages.confirmationText}
        primaryBtnText={DialogMessages.deleteBtnText}
        icon={<Icon name="DialogDeleteIcon" />}
      />
    </>
  );
};

export default HolidayRow;
