import { Divider } from "@mui/material";
import { Avatar, Box, Grid, Menu, Typography } from "@ntpkunity/controls";
import { useEffect, useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import { getInitials, getInternalAccessToken } from "utilities/methods";
import type { IUserInfo } from "Interface";
import { logout } from '@ntpkunity/controls-ums'

export const LayoutHeaderComponent: FC<{
  theme: any;
  breadcrumbs: { item: string; link: string }[];
}> = ({ theme, breadcrumbs }) => {
  const navigate = useNavigate();
  const [updatedBreadcrumb, setBreadCrumbs] = useState(null);
  const internalAccessToken: IUserInfo = getInternalAccessToken();
  useEffect(() => {
    setBreadCrumbs(breadcrumbs);
  }, []);
  const handleClick = (link: string) => {
    navigate(link);
  };
  const MenuOptions: any = [
    {
      optionText: "Logout",
      optionkey: "Logout",
      optionValue: "Logout",
    },
  ];

  const handleLogout = () => {
    
    logout()
    localStorage.clear()
    // const logoutRequest = {
    //   account: instance.getAllAccounts()[0],
    // };
    // instance.logoutRedirect(logoutRequest);
    // localStorage.removeItem(SEARCH_PARAMS_CACHED_KEY);
  };

  return (
    <Box theme={theme} component="header" sx={{ display: "flex" }}>
      <Grid theme={theme} direction="row" container>
        <Grid theme={theme} item xs={6} md={6} className="breadcrumbs">
          {updatedBreadcrumb?.map((item, key) => {
            return (
              <Typography
                key={key}
                theme={theme}
                component="span"
                variant="body2"
                className="tenant-title breadcrumbs-item"
                children={item.item}
                onClick={() => {
                  item.link && handleClick(item.link);
                }}
              />
            );
          })}
        </Grid>
        <Grid
          theme={theme}
          item
          display="flex"
          justifyContent="right"
          alignItems={"center"}
          xs={6}
          md={6}
          className="header-nav-right"
        >
          <Menu
            theme={theme}
            open={false}
            options={MenuOptions}
            render={(cb) => (
              <Box
                theme={theme}
                className="header-link-item user-link"
                onClick={cb}
              >
                <Avatar theme={theme}>
                  {getInitials(internalAccessToken.user_name)}
                </Avatar>
                <span className="status-icon" />
              </Box>
            )}
            handleOptionClick={(value) => {
              switch (value) {
                case "Logout":
                  handleLogout();
                  break;
              }
              handleLogout();
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </Grid>
        <Divider />
      </Grid>
    </Box>
  );
};
