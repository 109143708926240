import { FC, useState } from 'react'
import { useTheme, TablePagination } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Box, Button, DataTableWrap } from 'components'
import { unityTheme, Icon, Input, DataTable, Menu } from '@ntpkunity/controls'
import { IMenuOptions, IWQOrderOption, IWorkOrder } from '@models'
import { useNavigate } from 'react-router-dom'
import { Status, WorkQueueMenuOption } from '@helpers/enums'
import { formatedDate, dateFormat } from 'helpers/methods'
import ChevronDown from '@public/assets/icons/chevron-down'

const PendingConfirmationOrderTable: FC<IWQOrderOption> = ({
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  setColumnFilters,
  data,
  menuOptions,
  handleOrderSelection,
  showDealerColumn = false,
  canUserEdit = true
}) => {
  const theme = useTheme()
  let navigate = useNavigate()
  const [filters, setFilters] = useState<any>()
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage)
    setColumnFilters(setQueryString(newPage, pageSize))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value)
    setPageNumber(0)
    setColumnFilters(setQueryString(0, +event.target.value))
  }
  const setQueryString = (pgNo: number, pgSize: number): any => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}&multiple_order_status=${Status.PendingConfirmation}&multiple_order_status=${Status.InReservation}`
    if (
      filters?.requestTypeFilter?.trimStart() != null &&
      filters?.requestTypeFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&request_type=${filters?.requestTypeFilter}`)
    }
    if (filters?.orderIdFilter?.trimStart() != null && filters?.orderIdFilter?.trimStart() != '') {
      query_string = query_string.concat(`&reference_number=${filters?.orderIdFilter}`)
    }
    if (filters?.dealerFilter?.trimStart() != null && filters?.dealerFilter?.trimStart() != '') {
      query_string = query_string.concat(`&dealer_name=${filters?.dealerFilter}`)
    }
    if (filters?.statusFilter?.trimStart() != null && filters?.statusFilter?.trimStart() != '') {
      query_string = query_string.concat(`&order_status=${filters?.statusFilter}`)
    }
    if (filters?.assetFilter?.trimStart() != null && filters?.assetFilter?.trimStart() != '') {
      query_string = query_string.concat(`&make_model_trim=${filters?.assetFilter}`)
    }
    if (filters?.vinFilter?.trimStart() != null && filters?.vinFilter?.trimStart() != '') {
      query_string = query_string.concat(`&vin=${filters?.vinFilter}`)
    }
    if (
      filters?.stockNumberFilter?.trimStart() != null &&
      filters?.stockNumberFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&stock=${filters?.stockNumberFilter}`)
    }
    if (
      filters?.customerNameFilter?.trimStart() != null &&
      filters?.customerNameFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&customer_name=${filters?.customerNameFilter}`)
    }
    if (
      filters?.customerTypeFilter?.trimStart() != null &&
      filters?.customerTypeFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&customer_type=${filters?.customerTypeFilter}`)
    }
    if (
      filters?.financeTypeFilter?.trimStart() != null &&
      filters?.financeTypeFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&finance_type=${filters?.financeTypeFilter}`)
    }
    if (filters?.selling_price?.trimStart() != null && filters?.selling_price?.trimStart() != '') {
      query_string = query_string.concat(`&selling_price=${filters?.selling_price}`)
    }
    if (
      filters?.programNameFilter?.trimStart() != null &&
      filters?.programNameFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&program=${filters?.programNameFilter}`)
    }
    if (
      filters?.creationDateFilter?.trimStart() != null &&
      filters?.creationDateFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&created_at=${filters?.creationDateFilter}`)
    }
    if (
      filters?.expiryDateFilter?.trimStart() != null &&
      filters?.expiryDateFilter?.trimStart() != ''
    ) {
      query_string = query_string.concat(`&expiry_date=${filters?.expiryDateFilter}`)
    }
    return query_string
  }
  const handleFilter = () => {
    setPageNumber(0)
    setColumnFilters(setQueryString(0, pageSize))
  }
  const getOrderMenuOptions = (order: IWorkOrder) => {
    let orderMenuOptions: Array<IMenuOptions> = menuOptions
    if (order?.request_type != 'Application') {
      orderMenuOptions = orderMenuOptions.filter(
        (x) => x.optionText != WorkQueueMenuOption.ORDER_SUMMARY
      )
    }
    return orderMenuOptions
  }
  return (
    <>
      <DataTableWrap className="table-pagination">
        <Box className="scroll">
          <Box className="scroll-hide sh-fixed-cell spr-border" theme={unityTheme} />
        </Box>
        <DataTable
          theme={unityTheme}
          variant="basic"
          theadChildren={
            <>
              <TableRow>
                <TableCell className="img-cell">
                  <Icon name="ImageIcon" />
                </TableCell>
                <TableCell id="productNameHead">Request Type</TableCell>
                <TableCell id="descriptionHead">Deal ID</TableCell>
                {showDealerColumn && <TableCell id="dealer">Dealer</TableCell>}
                <TableCell id="categoryHead">Request Status</TableCell>
                <TableCell id="priceHead">Year / Make / Model / Trim</TableCell>
                <TableCell id="installationModeHead">VIN</TableCell>
                <TableCell id="supplierHead">Stock Number</TableCell>
                <TableCell id="supplierHead">Customer Name</TableCell>
                <TableCell id="supplierHead">Customer Type</TableCell>
                <TableCell id="supplierHead">Finance Type</TableCell>
                <TableCell id="supplierHead">Selling Price</TableCell>
                <TableCell id="supplierHead">Program Name</TableCell>
                <TableCell id="supplierHead">Creation Date</TableCell>
                <TableCell id="supplierHead">Expiry Date</TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell className="img-cell"></TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'requestTypefilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, requestTypeFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.requestTypeFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="productFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'orderIdFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, orderIdFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.orderIdFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="descriptionFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                {showDealerColumn && (
                  <TableCell>
                    <Box theme={theme} className="table-filter">
                      <Input
                        theme={theme}
                        fullWidth={true}
                        placeholder={'Search...'}
                        type="text"
                        startAdornment={undefined}
                        endAdornment={undefined}
                        id={'dealerFilter'}
                        onChange={(value: string) => {
                          setFilters({ ...filters, dealerFilter: value })
                        }}
                        onBlur={() => {
                          handleFilter()
                        }}
                        value={filters?.dealerFilter?.trimStart()}
                      />
                      <Button
                        defaultBtn
                        id="dealerFilterBtn"
                        iconText={<Icon name="IconFilter" />}
                      ></Button>
                    </Box>
                  </TableCell>
                )}
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'statusFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, statusFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.statusFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="categoryFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'assetFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, assetFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.assetFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="priceFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'vinFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, vinFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.vinFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="installationModeFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'stockNumberFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, stockNumberFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.stockNumberFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'customerNameFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, customerNameFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.customerNameFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'customerTypeFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, customerTypeFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.customerTypeFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'financeTypeFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, financeTypeFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.financeTypeFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'SellingPriceFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, selling_price: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.selling_price?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'programNameFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, programNameFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.programNameFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'creationDateFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, creationDateFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.creationDateFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      placeholder={'Search...'}
                      type="text"
                      startAdornment={undefined}
                      endAdornment={undefined}
                      id={'expiryDateFilter'}
                      onChange={(value: string) => {
                        setFilters({ ...filters, expiryDateFilter: value })
                      }}
                      onBlur={() => {
                        handleFilter()
                      }}
                      value={filters?.expiryDateFilter?.trimStart()}
                    />
                    <Button
                      defaultBtn
                      id="supplierFilter"
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              {data?.result?.map((workOrder: IWorkOrder, index: number) => (
                <TableRow
                  key={index}
                  className="child-tr"
                  onDoubleClick={(_e: any) => {
                    if (!canUserEdit) return
                    if (workOrder?.status === Status.Conditioned) {
                      navigate(
                        `/lane/deal-stipulation/?vin=${workOrder.vin}&reference_id=${workOrder.reference_number}&customer_reference_id=${workOrder?.customer_reference_id}`
                      )
                    } else if (workOrder?.status == Status.Approved) {
                      navigate(`/lane/deal-approved/${workOrder?.reference_number}`)
                    } else if (
                      workOrder?.status == Status.Submitted ||
                      workOrder?.status == Status.PendingConfirmation ||
                      workOrder?.status == Status.InReservation
                    ) {
                      navigate(`/lane/deal-summary/${workOrder?.reference_number}`)
                    } else {
                      navigate(
                        `/lane/create-deal-ui/${workOrder.vin}/${workOrder.customer_reference_id}/${workOrder.reference_number}`
                      )
                    }
                  }}
                >
                  <TableCell className="img-cell">
                    <img
                      className="prd-img"
                      src={workOrder?.photo_urls?.[0]?.location.toString()}
                      alt="Car"
                    />
                  </TableCell>
                  <TableCell id={'request_type'}>{workOrder?.order_stage}</TableCell>
                  <TableCell id={'order_id' + workOrder?.reference_number}>
                    {workOrder?.reference_number}
                  </TableCell>
                  {showDealerColumn && (
                    <TableCell id={'dealer' + workOrder?.dealer_name}>
                      {workOrder?.dealer_name}
                    </TableCell>
                  )}
                  <TableCell id={'status' + workOrder?.status}>{workOrder?.status}</TableCell>
                  <TableCell
                    id={
                      'assets' +
                      workOrder?.year +
                      ' ' +
                      workOrder?.make +
                      ' ' +
                      workOrder?.model +
                      ' ' +
                      workOrder?.trim_description
                    }
                  >
                    {workOrder?.year +
                      ' ' +
                      workOrder?.make +
                      ' ' +
                      workOrder?.model +
                      ' ' +
                      workOrder?.trim_description}
                  </TableCell>
                  <TableCell id={'vin' + workOrder?.vin}>{workOrder?.vin}</TableCell>
                  <TableCell>{workOrder?.stock_number}</TableCell>
                  <TableCell id={'name' + workOrder?.first_name}>
                    {`${workOrder?.first_name || ''} ${workOrder?.middle_name || ''} ${
                      workOrder?.last_name || ''
                    }`}
                  </TableCell>
                  <TableCell id={'customer' + workOrder?.customer_type}>
                    {workOrder?.customer_type ?? 'Individual'}
                  </TableCell>
                  <TableCell id={'finance_type' + workOrder?.finance_type}>
                    {workOrder.finance_type}
                  </TableCell>
                  <TableCell id={'selling_price' + workOrder?.selling_price}>
                    {workOrder.selling_price?.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </TableCell>
                  <TableCell id={'program_name' + workOrder?.program_name}>
                    {workOrder.program_name}
                  </TableCell>
                  <TableCell id={'creation_date' + workOrder?.created_at}>
                    {workOrder?.created_at &&
                      formatedDate(new Date(workOrder?.created_at), dateFormat)}
                  </TableCell>
                  <TableCell id={'expiry_date' + workOrder?.expired_at}>
                    {workOrder?.expired_at &&
                      workOrder?.status == Status.Draft &&
                      formatedDate(new Date(workOrder?.expired_at), dateFormat)}
                  </TableCell>
                  <TableCell className="action-cell fixed-cell">
                    <Menu
                      theme={theme}
                      options={getOrderMenuOptions(workOrder).map((option: any) => {
                        return {
                          optionText: option.optionText,
                          optionkey: option.optionkey,
                          optionValue: workOrder
                        }
                      })}
                      handleOptionClick={handleOrderSelection}
                      render={(onMenuSelection: any) =>
                        !canUserEdit ? (
                          <></>
                        ) : (
                          <Button
                            defaultBtn
                            iconText={<Icon name="MoreIcon" />}
                            onClick={onMenuSelection}
                          />
                        )
                      }
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          }
        />

        {data && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            count={data?.total_results ?? -1}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              IconComponent: ChevronDown
            }}
          />
        )}
      </DataTableWrap>
    </>
  )
}

export default PendingConfirmationOrderTable
