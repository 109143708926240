import { useTheme } from "@mui/material";
import {
  AddressLayout,
  Button,
  Dialog,
  Grid,
  Input,
  Textarea,
  Typography,
} from "@ntpkunity/controls";
import { DownloadQuoteWrap } from "./download-quote-dialog.style";
import { useForm, Controller } from "react-hook-form";
import {
  DownloadQuoteAdditionalParams,
  QuotationDownloadDialogData,
} from "@_types/quotation";
import { ClientTypes, TableRowOptions } from "@helpers/enum";
import { GB_COUNTRY, QUOTATION_SETTINGS_TO_MAP } from "@helpers/const";

type DownloadQuotationDialogProps = {
  downloadQuotation: (
    params: DownloadQuoteAdditionalParams,
    identifier?: string
  ) => void;
  dialogOpen: boolean;
  closeDialog: () => void;
  dialogData: QuotationDownloadDialogData;
  btnDisabled?: boolean;
};

export const DownloadQuotationDialog = ({
  downloadQuotation,
  dialogOpen,
  closeDialog,
  dialogData,
  btnDisabled = false,
}: DownloadQuotationDialogProps) => {
  const { control, getValues } = useForm<DownloadQuoteAdditionalParams>();

  const variantText = QUOTATION_SETTINGS_TO_MAP[dialogData.rowOptions];
  const isSoftCreditCheck =
    dialogData.rowOptions === TableRowOptions.SOFT_CREDIT_CHECK;

  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      title={
        isSoftCreditCheck
          ? QUOTATION_SETTINGS_TO_MAP[TableRowOptions.SOFT_CREDIT_CHECK]
          : `${variantText} Quotation`
      }
      size="sm"
      open={dialogOpen}
      onClose={closeDialog}
      customFooter={
        <>
          <Button
            theme={theme}
            type="button"
            secondary
            text="Cancel"
            onClick={closeDialog}
          />
          <Button
            theme={theme}
            type="button"
            primary
            text={isSoftCreditCheck ? "Submit" : variantText}
            disabled={btnDisabled}
            onClick={() => {
              downloadQuotation(getValues(), dialogData.identifier);
            }}
          />
        </>
      }
    >
      <DownloadQuoteWrap theme={theme} className="download-quote-wrap">
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={12}>
            <Typography theme={theme} component={"p"} variant="subtitle1">
              {isSoftCreditCheck ? "Customer Details" : "Details"}
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    fullWidth
                    placeholder="Type here..."
                    label="Customer Email"
                    type="text"
                    dynamic={false}
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="fullName"
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    placeholder="Type here..."
                    label="Customer Full Name"
                    type="text"
                    fullWidth
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Controller
              control={control}
              name="idNumber"
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    placeholder="Type here..."
                    label={
                      dialogData.clientType === ClientTypes.INDIVIDUAL
                        ? "Id Number"
                        : "Company Registration Number"
                    }
                    type="text"
                    fullWidth
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          {isSoftCreditCheck ? (
            <>
              <Grid theme={theme} item xs={12}>
                <AddressLayout
                  theme={theme}
                  state={{
                    data: [],
                    selectedItemObject: {
                      text: "",
                      value: "",
                    },
                    controlName: "",
                  }}
                  value={{}}
                  onChange={() => {}}
                  isContactRequired={false}
                  addressVerificationRequired={false}
                  validationProps={undefined}
                  hideAddressButton={true}
                  countries={[GB_COUNTRY]}
                  showCountryDropdown
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid theme={theme} item xs={12}>
                <Controller
                  control={control}
                  name="lenderName"
                  render={({ field }) => {
                    return (
                      <Input
                        theme={theme}
                        placeholder="Type here..."
                        label="Lender Name"
                        type="text"
                        fullWidth
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid theme={theme} item xs={12}>
                <Controller
                  control={control}
                  name="comments"
                  render={({ field }) => {
                    return (
                      <Textarea
                        theme={theme}
                        placeholder="Type here..."
                        label="Comments"
                        fullWidth
                        type="text"
                        id="textAreaId"
                        rows={3}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DownloadQuoteWrap>
    </Dialog>
  );
};
