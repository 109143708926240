import {
  IntegrationType,
  Providers,
} from "../Enums/IntegrationTypesAndProviders";

const socure_items = [
  { text: "Decision", value: "decision", disabled: true },
  { text: "Address Risk", value: "addressrisk" },
  { text: "Alert List", value: "alertlist" },
  { text: "Device Risk", value: "devicerisk" },
  {
    text: "Document Verification",
    value: "documentverification",
    disabled: true,
  },
  { text: "Email Risk", value: "emailrisk" },
  { text: "Fraud", value: "fraud" },
  { text: "KYC", value: "kyc" },
  { text: "Phone Risk", value: "phonerisk" },
  { text: "Synthetic", value: "synthetic" },
  { text: "Global Watch list", value: "watchlistpremier" },
];
const data = {
  integration_types: [
    {
      integration_type: IntegrationType.PAYMENT_PROCESSING,
      subscription: "company",
      provider_name: [
        {
          name: "Stripe Connect",
          value: Providers.STRIPE,
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "api_version",
              value: "",
              data_type: "text",
              label: "API Version",
            },
          ],
        },
        {
          name: "Plaid",
          value: Providers.PLAID,
          subscription: "company",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "app_name",
              value: "",
              data_type: "text",
              label: "APP Name",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "api_version",
              value: "",
              data_type: "text",
              label: "API Version",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CUSTOMER_MANAGEMENT,
      subscription: "both",
      provider_name: [
        {
          name: "CDK",
          value: Providers.CDK,
          is_active: "true",
          at_company_level: true,
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "api_key",
              value: "",
              data_type: "text",
              label: "API Key",
            },
            {
              name: "api_secret",
              value: "",
              data_type: "text",
              label: "API Secret",
            },
            {
              name: "department_id",
              value: "",
              data_type: "text",
              label: "API DEPARTMENT ID",
            },
            {
              name: "subscription_id",
              value: "",
              data_type: "text",
              label: "Subscription ID",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
          ],
        },
        {
          name: "Tekion",
          value: Providers.TEKION,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "access_key",
              value: "",
              data_type: "text",
              label: "API Access Key",
            },
            {
              name: "secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "API Client ID",
            },
            {
              name: "dealer_id",
              value: "",
              data_type: "text",
              label: "Dealer ID",
            },
          ],
        },
        {
          name: "Dealer Track",
          value: Providers.DEALERTRACK,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },

            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "enterprise_code",
              value: "",
              data_type: "text",
              label: "Enterprise Code",
            },
            {
              name: "company_number",
              value: "",
              data_type: "text",
              label: "Company Number",
            },
            {
              name: "host",
              value: "",
              data_type: "text",
              label: "Host",
            },
          ],
        },
        {
          name: "Ascent",
          value: Providers.ASCENT,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.INVENTORY_MANAGEMENT,
      subscription: "company",
      provider_name: [
        {
          name: "Shift Digital",
          value: Providers.SHIFTDIGITAL,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "host",
              value: "",
              data_type: "text",
              label: "Host",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "port",
              value: "",
              data_type: "text",
              label: "Port",
            },
            {
              name: "shift_digital_id",
              value: "",
              data_type: "text",
              label: "Shift digital ID",
            },
          ],
        },
        {
          name: "BMW",
          value: Providers.BMW_INVENTORY,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API Base URL",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Access Token URL",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.FNI_PRODUCTS,
      subscription: "company",
      provider_name: [
        {
          name: "Provider Exchange Network",
          value: Providers.PEN,
          is_active: "true",
          subscription: "company",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "user_name",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "token",
              value: "",
              data_type: "text",
              label: "Token",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API  Base Url",
            },
          ],
        },
        {
          name: "BMW",
          is_active: "true",
          value: Providers.BMW_PROGRAMS,
          subscription: "company",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "API Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.FRAUD_IDENTIFICATION,
      subscription: "company",
      provider_name: [
        {
          name: "Socure",
          value: Providers.SOCURE,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "time_to_live",
              value: "",
              data_type: "text",
              label: "Days",
              default_value: "0",
            },
            {
              name: "module_types",
              value: [],
              data_type: "multiselect",
              items: socure_items,
              label: "Modules",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.IDENTITY_VERIFICATION,
      subscription: "both",
      provider_name: [
        {
          name: "Mitek",
          value: Providers.MITEK,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Identity ​Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Identity Client Secret",
            },
            {
              name: "manual_client_id",
              value: "",
              data_type: "text",
              label: "​Manual Client ID",
            },
            {
              name: "manual_client_secret",
              value: "",
              data_type: "text",
              label: "Manual Client Secret",
            },
            {
              name: "manual_api_base_url",
              value: "",
              data_type: "text",
              label: "Manual Base Url",
            },
            {
              name: "identity_api_base_url",
              value: "",
              data_type: "text",
              label: "Identity Base Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.LOCATION_SERVICE,
      subscription: "both",
      provider_name: [
        {
          name: "Google",
          value: Providers.GOOGLE,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CO_BROWSING,
      subscription: "both",
      provider_name: [
        {
          name: "Upscope",
          value: Providers.UPSCOPE,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "script_key",
              value: "",
              data_type: "text",
              label: "Script Key",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.TRADEIN_VALUATION,
      subscription: "dealer",
      provider_name: [
        {
          name: "Kelly Blue Book",
          value: Providers.KBB,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "base_url",
              value: "",
              data_type: "text",
              label: "Api Base Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.PURCHASE_INSURANCE,
      subscription: "both",
      provider_name: [
        {
          name: "way.com",
          value: Providers.WAY,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "false",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "Api Base Url",
            },
            {
              name: "auth_token",
              value: "",
              data_type: "text",
              label: "Auth Token",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.DEAL_PUSH,
      subscription: "dealer",
      provider_name: [
        {
          name: "Tekion",
          value: Providers.TEKION,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "access_key",
              value: "",
              data_type: "text",
              label: "API Access Key",
            },
            {
              name: "secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "API Client ID",
            },
            {
              name: "dealer_id",
              value: "",
              data_type: "text",
              label: "Dealer ID",
            },
          ],
        },
        {
          name: "CDK",
          value: "Cdk",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "api_key",
              value: "",
              data_type: "text",
              label: "API Key",
            },
            {
              name: "api_secret",
              value: "",
              data_type: "text",
              label: "API Secret",
            },
            {
              name: "department_id",
              value: "",
              data_type: "text",
              label: "API Department ID",
            },
            {
              name: "subscription_id",
              value: "",
              data_type: "text",
              label: "Subscription ID",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
          ],
        },
        {
          name: "Dealer Track",
          value: "DealerTrack",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "enterprise_code",
              value: "",
              data_type: "text",
              label: "Enterprise Code",
            },
            {
              name: "company_number",
              value: "",
              data_type: "text",
              label: "Company Number",
            },
            {
              name: "host",
              value: "",
              data_type: "text",
              label: "Host",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.LEAD_GENERATION_AND_MANAGEMENT,
      subscription: "dealer",
      provider_name: [
        {
          name: "CDK",
          value: Providers.CDK,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "api_key",
              value: "",
              data_type: "text",
              label: "API Key",
            },
            {
              name: "api_secret",
              value: "",
              data_type: "text",
              label: "API Secret",
            },
            {
              name: "department_id",
              value: "",
              data_type: "text",
              label: "API DEPARTMENT ID",
            },
            {
              name: "subscription_id",
              value: "",
              data_type: "text",
              label: "Subscription ID",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
          ],
        },
        {
          name: "Shift Digital",
          value: Providers.SHIFTDIGITAL_LEADS,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CALCULATION_AND_TAXATION,
      subscription: "company",
      provider_name: [
        {
          name: "Market Scan",
          value: Providers.MARKETSCAN,
          subscription: "company",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "partner_id",
              value: "",
              data_type: "text",
              label: "Partner ID",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "account_number",
              value: "",
              data_type: "text",
              label: "Account Number",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.E_SIGNATURE,
      subscription: "company",
      provider_name: [
        {
          name: "DocuSign",
          value: Providers.DOCUSIGN,
          subscription: "both",
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_base_url",
              value: "",
              data_type: "text",
              label: "API Base URL",
            },
            {
              name: "account_id",
              value: "",
              data_type: "text",
              label: "Account ID",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "secret_key",
              value: "",
              data_type: "text",
              label: "Secret Key",
            },
            {
              name: "oauth_hostname",
              value: "",
              data_type: "text",
              label: "Hostname",
            },
            {
              name: "api_version",
              value: "",
              data_type: "text",
              label: "API Version",
            },
            {
              name: "user_id",
              value: "",
              data_type: "text",
              label: "User ID",
            },
            {
              name: "jwt_oauth_hostname",
              value: "",
              data_type: "text",
              label: "OAuth Hostname",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.DEALER_LENDER_ASSOCIATION,
      subscription: "dealer",
      provider_name: [
        {
          name: "Dealer Track",
          value: Providers.DEALER_TRACK_LENDER,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "partner_dealer_id",
              value: "",
              data_type: "text",
              label: "Dealer Partner ID",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
          ],
        },
        {
          name: "Route One",
          value: Providers.ROUTEONE,
          is_active: "true",
          is_dealer_input_required: "false",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API Base URL",
            },
            {
              name: "dealership_id",
              value: "",
              data_type: "text",
              label: "Dealership ID",
            },
            {
              name: "user_id",
              value: "",
              data_type: "text",
              label: "User ID",
            },
            {
              name: "partner_id",
              value: "",
              data_type: "text",
              label: "Partner ID",
            },
            {
              name: "sha_body_content",
              value: "",
              data_type: "text",
              label: "SHA Content",
            },
            {
              name: "hmac_ss",
              value: "",
              data_type: "text",
              label: "HMAC SS",
            },
            {
              name: "api_hash",
              value: "",
              data_type: "text",
              label: "API Hash",
            },
            {
              name: "hmac_id",
              value: "",
              data_type: "text",
              label: "HMAC ID",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook URL",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.PRICING_PROGRAM,
      subscription: "company",
      provider_name: [
        {
          name: "BMW",
          value: Providers.BMW_PRICING,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "est_client_id",
              value: "",
              data_type: "text",
              label: "Payment Estimator Client ID",
            },
            {
              name: "est_client_secret",
              value: "",
              data_type: "text",
              label: "Payment Estimator Client Secret",
            },
          ],
        },
        {
          name: "Ascent",
          value: Providers.ASCENT,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CREDIT_DECISIONING,
      subscription: "company",
      provider_name: [
        {
          name: "BMW",
          value: Providers.BMW_DEALS,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
        {
          name: "Ascent",
          value: Providers.ASCENT,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
        {
          name: "Dealer Track",
          value: Providers.DEALER_TRACKML,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_key",
              value: "",
              data_type: "text",
              label: "API Key",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "source_partner_id",
              value: "",
              data_type: "text",
              label: "Source Partner ID",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
        {
          name: "Route One",
          value: Providers.ROUTEONE,
          is_active: "true",
          is_dealer_input_required: "false",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API Base URL",
            },
            {
              name: "dealership_id",
              value: "",
              data_type: "text",
              label: "Dealership ID",
            },
            {
              name: "user_id",
              value: "",
              data_type: "text",
              label: "User ID",
            },
            {
              name: "partner_id",
              value: "",
              data_type: "text",
              label: "Partner ID",
            },
            {
              name: "sha_body_content",
              value: "",
              data_type: "text",
              label: "SHA Content",
            },
            {
              name: "hmac_ss",
              value: "",
              data_type: "text",
              label: "HMAC SS",
            },
            {
              name: "api_hash",
              value: "",
              data_type: "text",
              label: "API Hash",
            },
            {
              name: "hmac_id",
              value: "",
              data_type: "text",
              label: "HMAC ID",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook URL",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CONTRACTING,
      subscription: "company",
      provider_name: [
        {
          name: "BMW",
          value: Providers.BMW_CONTRACTS,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
        {
          name: "Ascent",
          value: Providers.ASCENT,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Token Url",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook Url",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.TAGGING,
      subscription: "company",
      provider_name: [
        {
          name: "Shift Digital",
          value: Providers.SHIFT_DIGITAL,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "provider_id",
              value: "",
              data_type: "text",
              label: "Provider ID",
            },
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API Base URL",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.CREDIT_BUREAU,
      subscription: "company",
      provider_name: [
        {
          name: "TransUnion",
          value: Providers.TRANSUNION,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API Base URL",
            },
            {
              name: "system_id",
              value: "",
              data_type: "text",
              label: "System ID",
            },
            {
              name: "system_password",
              value: "",
              data_type: "text",
              label: "System Password",
            },
            {
              name: "industry_code",
              value: "",
              data_type: "text",
              label: "Industry Code",
            },
            {
              name: "member_code",
              value: "",
              data_type: "text",
              label: "Member Code",
            },
            {
              name: "subscriber_code",
              value: "",
              data_type: "text",
              label: "Subscriber Prefix Code",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "product_version",
              value: "",
              data_type: "text",
              label: "Product Version",
            },
            {
              name: "product_code_addon",
              value: "",
              data_type: "text",
              label: "Add-On Product Code",
            },
            {
              name: "time_to_live",
              value: "",
              data_type: "text",
              label: "Time to Live (cache period)",
            },
            {
              name: "caching_type",
              value: "",
              items: [
                { text: "Complete Response ", value: "CompeleteResponse" },
                { text: "Credit Score Only", value: "CreditScoreOnly" },
              ],
              data_type: "select",
              label: "Response Saving",
            },
          ],
        },
        {
          name: "Experian",
          value: Providers.EXPERIAN,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "client_reference_id",
              value: "",
              data_type: "text",
              label: "Client reference ID",
            },
            {
              name: "api_version",
              value: "",
              data_type: "text",
              label: "API version",
            },
          ],
        },
        {
          name: "Equifax",
          value: Providers.EQUIFAX,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "api_version",
              value: "",
              data_type: "text",
              label: "API version",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.INVENTORY_CHECK,
      subscription: "dealer",
      provider_name: [
        {
          name: "Tekion",
          is_active: "true",
          value: Providers.TEKION,
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "access_key",
              value: "",
              data_type: "text",
              label: "API Access Key",
            },
            {
              name: "secret_key",
              value: "",
              data_type: "text",
              label: "API Secret Key",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "API Client ID",
            },
            {
              name: "dealer_id",
              value: "",
              data_type: "text",
              label: "Dealer ID",
            },
          ],
        },
        {
          name: "Dealer Track",
          value: Providers.DEALERTRACK,
          is_active: "true",
          is_dealer_input_required: "true",
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API base url",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "enterprise_code",
              value: "",
              data_type: "text",
              label: "Enterprise Code",
            },
            {
              name: "company_number",
              value: "",
              data_type: "text",
              label: "Company Number",
            },
            {
              name: "host",
              value: "",
              data_type: "text",
              label: "Host",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.PROPOSAL_SUBMISSION,
      subscription: "company",
      provider_name: [
        {
          name: "Siemens",
          value: Providers.SIEMENS,
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "Base URL",
            },
            {
              name: "token_url",
              value: "",
              data_type: "text",
              label: "Access Token URL",
            },
            {
              name: "client_id",
              value: "",
              data_type: "text",
              label: "Client ID",
            },
            {
              name: "client_secret",
              value: "",
              data_type: "text",
              label: "Client Secret",
            },
            {
              name: "audience",
              value: "",
              data_type: "text",
              label: "Audience",
            },
            {
              name: "webhook_url",
              value: "",
              data_type: "text",
              label: "Webhook URL",
            },
          ],
        },
      ],
    },
    {
      integration_type: IntegrationType.PAYOFF_QUOTES,
      subscription: "company",
      provider_name: [
        {
          name: "RouteOne",
          value: Providers.PAYOFF_ROUTEONE,
          user_input: [
            {
              name: "api_url",
              value: "",
              data_type: "text",
              label: "API Base URL",
            },
            {
              name: "soap_action_url",
              value: "",
              data_type: "text",
              label: "SOAP Action URL",
            },
            {
              name: "username",
              value: "",
              data_type: "text",
              label: "Username",
            },
            {
              name: "password",
              value: "",
              data_type: "text",
              label: "Password",
            },
            {
              name: "partner_id",
              value: "",
              data_type: "text",
              label: "Partner ID",
            },
            {
              name: "payoff_dealer_id",
              value: "",
              data_type: "text",
              label: "Dealer ID",
            },
          ],
        },
      ],
    },
  ],
};

export default data;
