import { useTheme } from "@mui/material";
import { QuotationFiltersWrap } from "./new-quotation-header.style";
import { BrokerSpecifcFormFields } from "@_types/forms";
import { Box, Select, Typography } from "@ntpkunity/controls";
import { useHasPermissions } from "@ntpkunity/controls-ums";
import { getSelectValues, removeDeletedEntities } from "@helpers/utils";
import { QuotationFormController } from "@ntpkunity/controls-common";
import { useFormContext } from "react-hook-form";
import { useAssetSetups } from "@hooks/proposal";
import { useSetupsSelector } from "@hooks/useGetSetups";
import { useState } from "react";
import ChooseAssetDialog from "@components/proposal/asset-details/choose-asset-dialog/choose-asset-dialog";
import { UserPermissions } from "@helpers/enum";

export const NewQuotationFiltersHeader = () => {
  const theme = useTheme();
  const [assetDialogOpen, setAssetDialogOpen] = useState<boolean>(false);
  const { control } = useFormContext<BrokerSpecifcFormFields>();
  const { concatenations } = useAssetSetups();

  const clientTypes = useSetupsSelector((state) => state.clientTypes);

  return (
    <QuotationFiltersWrap theme={theme} className="quotation-filters-wrap">
      <Box theme={theme} className="filters-area">
        {useHasPermissions(UserPermissions.SEARCH_INVENTORY) && (
          <Box theme={theme} className="filter-item no-bg">
            <Typography
              theme={theme}
              variant="body2"
              component="span"
              className="text-primary fw-medium"
              display={"block"}
            >
              <a
                href="javascript:void(0)"
                className="link text-primary decoration-none"
                onClick={() => {
                  setAssetDialogOpen(true);
                }}
              >
                Search Inventory
              </a>
            </Typography>
          </Box>
        )}
        <Box theme={theme} className="filter-item">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Asset Type:
          </Typography>
          <QuotationFormController
            name="asset.type"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={getSelectValues(concatenations)}
                {...field}
              />
            )}
          />
        </Box>
        <Box theme={theme} className="filter-item">
          <Typography
            theme={theme}
            variant="body2"
            component={"span"}
            display={"block"}
            className="text-muted"
          >
            Client Type:
          </Typography>
          <QuotationFormController
            name="clientType"
            control={control}
            render={({ field }) => (
              <Select
                disablePortal={false}
                theme={theme}
                items={removeDeletedEntities(undefined, clientTypes.data)?.map(
                  (ct) => ({
                    text: ct.description,
                    value: ct.code,
                  })
                )}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
      <ChooseAssetDialog open={assetDialogOpen} setOpen={setAssetDialogOpen} />
    </QuotationFiltersWrap>
  );
};
