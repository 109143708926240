export enum AuthenticationTypes {
  UNITY = "unity",
}

export enum ClientTypes {
  LIMITED_COMPANY = "Limited company",
  INDIVIDUAL = "Individual",
  PARTNERSHIP = "Partnership",
  SOLE_TRADER = "Sole Trader",
  GOV_ORG = "Government Organization",
  NON_PROFIT_ORG = "Not-for-profit Organization",
  SME = "SME",
}

export enum HardAssetConditions {
  NEW = "New",
  USED = "Used",
}

export enum AssetCategories {
  SOFT_ASSET = "Soft Asset",
  HARD_ASSET = "Hard Asset",
}

export enum SignatoryRowOptions {
  VIEW_EDIT = "view-edit",
  DELETE = "delete",
}

export enum TableRowOptions {
  VIEW_EDIT = "view-edit",
  DELETE = "delete",
  VIEW = "view",
  UPLOAD = "upload",
  EDIT = "edit",
  CONVERT_TO_PROPOSAL = "convert-to-proposal",
  DOWNLOAD = "download",
  CLONE_QUOTATION = "clone-quotation",
  EMAIL_QUOTATION = "email-quotation",
  CLONE = "clone",
  WITHDRAW = "withdraw",
  OPEN_DIARY = "open-diary",
  PROPOSAL_SUMMARY = "proposal-summary",
  GENERATE_DOCUMENTS = "generate-documents",
  CHECK_SIGNATORY_STATUS = "signatory-status",
  VIEW_CONDITIONS = "view-conditions",
  SEND_FOR_PAYOUT = "send-for-payout",
  MANUAL_STATUS_CHANGE = "manual-status-change",
  VIEW_SIGNATORIES = "view-signatories",
  GENERATE_COMPLIANCE_DOCUMENTS = "generate-compliance-documents",
  VIEW_COMPLIANCE_DOCUMENTS = "view-compliance-documents",
  SOFT_CREDIT_CHECK = "soft-credit-check",
}

export enum ProposalTabs {
  PROPOSAL_DETAILS = "Proposal Details",
  ASSET_DETAILS = "Asset Details",
  FINANCE_DETAILS = "Finance Details",
  CREDIT_DOCUMENTS = "Credit Documents",
  SUMMARY = "Summary",
}

export enum OrderStage {
  APPLICATION = "Application",
  QUOTATION = "Quotation",
}

export enum WorkQueueTabs {
  QUOTATIONS = "quotations",
  DRAFT_PROPOSALS = "draft-proposals",
  TRACK_PROPOSALS = "track-proposals",
  DEALER_PROPOSALS = "dealer-proposals",
  DASHBOARD_PROPOSALS = "dashboard-proposals",
}

export enum UserPermissions {
  DASHBOARD = "dashboard",
  INTRODUCER_DASHBOARD = "introducer-dashboard",
  LENDER_DASHBOARD = "lender-dashboard",
  PROPOSAL_AND_QUOTATIONS = "proposal-and-quotations",
  CREATE_NEW_QUOTATION = "create-new-quotation",
  CREATE_NEW_PROPOSAL = "create-new-proposal",
  WORKQUEUE = "workqueue",
  ADMIN_OPTIONS = "admin-options",
  ADMIN_OPTION_CONFIGURATIONS = "admin-option-configurations",
  ADMIN_OPTION_SETUPS = "admin-option-setups",
  USER_MANAGEMENT = "user-management",
  BROKER_TRACK_PROPOSALS_WORKQUEUE = "broker-track-proposals-workqueue",
  BROKER_DRAFT_PROPOSALS_WORKQUEUE = "broker-draft-proposals-workqueue",
  BROKER_QUOTATIONS_WORKQUEUE = "broker-quotations-workqueue",
  BROKER_DEALER_PROPOSALS_WORKQUEUE = "broker-dealer-proposals-workqueue",
  LENDER_NAME = "lender-name",
  DEALER_NAME = "dealer-name",
  UPDATE_RATES_BUTTON = "update-rates-button",
  SEARCH_INVENTORY = "search-inventory",
}

export enum OrderStatus {
  DRAFT = "Draft",
  NEW = "New",
  WITHDRAWN = "Withdrawn",
  SUBMITTED = "Submitted",
  CONDITIONED = "Conditioned",
  CONDITIONAL_APPROVAL = "Conditional Approval",
  ADDITIONAL_INFO_REQUIRED = "Additional Info Required",
  DECLINED = "Declined",
  DOCUMENT_SENT = "Documents Sent",
  DOCUMENT_RECEIVED = "Documents Received",
  SENT_FOR_PAYOUT = "Sent for Payout",
  PAID_OUT = "Paid Out",
  ACCEPTED = "Approved",
  AWAITING_COMPLIANCE = "Awaiting Compliance",
  COMPLIED = "Complied",
}

export enum OrderStatusCategory {
  ACTIVE = "Active",
  CLOSED = "Closed",
}

export enum AmountType {
  POUND = "£",
  PERCENTAGE = "%",
}

export enum ProposalStakeholders {
  DEALER = "Dealer",
  LENDER = "Lender",
}

export enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum SnackbarVariants {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
}

export enum ComparisonViewMode {
  INPUT = "input",
  RESULT = "result",
}

export enum DashboardRangeFilter {
  WEEK = "Week",
  MONTH = "Month",
  QUARTER = "Quarter",
  CALENDER_YEAR = "CalenderYear",
}

export enum PromiseStates {
  REJECTED = "rejected",
}

export enum ApplicationMode {
  DEALER = "dealer",
}

export enum Roles {
  BROKER_ADMIN = "Broker Admin",
  BROKER_DEALER_ADMIN = "Broker Dealer Admin",
  BROKER_DEALER_USER = "Broker Dealer User",
  BROKER_USER = "Broker User",
}
export enum DocumentTypes {
  CREDIT_DOCUMENTS = "Credit Documents",
  COMPLIANCE = "Compliance",
}

export enum ProposalAgeCategories {
  LESS_THAN_TWO = "<2 Days",
  THREE_TO_FIVE = "3 - 5",
  SIX_TO_TEN = "6 - 10",
  MORE_THAN_TEN = ">10",
}
export enum AppDialogKeys {
  MANUAL_STATUS_CHANGE = "manual-status-change",
  VIEW_CONDITIONS = "view-conditions",
  WITHDRAW_CONFIRMATION = "withdraw-confirmation",
  COMPANY_LOOKUP = "company-lookup",
  ADDRESS_LOOKUP = "address-lookup",
}

export enum CompaniesLookupTabs {
  BY_NAME = "Company Name",
  BY_REG_NUM = "Registration Number",
}

export enum ActionOptions {
  LOGOUT = "Logout",
}

export enum CommentCategory {
  GENERAL = "General",
  UNDERWRITING = "Underwriting",
  CHANGE_REQUESTS = "Change Requests",
}

export enum SessionStorageKeys {
  QUOTATION_INPUTS = "QUOTATION_INPUTS",
}

export enum FinanceType {
  HIRE_PURCHASE = "Hire Purchase",
  FINANCE_LEASE = "Finance Lease",
  LOAN = "Loan",
  PERSONAL_CONTRACT_PURCHASE = "Personal Contract Purchase",
}

export enum DirectorRoles {
  PARTNER = "Partner",
  DIRECTOR = "Director",
  CONTACT_PERSON = "Contact Person",
}

export enum NotificationType {
  EMAIL = "Email",
  SMS = "SMS",
  DOCUMENT = "Document",
}

export enum Events {
  GENERATE_COMPLIANCE_DOCUMENTS = "Generate Compliance Documents",
  EMAIL_QUOTATION = "Email Quotation",
  EMAIL_COMPLIANCE_DOCUMENTS = "Email Compliance Documents",
  DOWNLOAD_QUOTATION = "Download Quotation",
  DOWNLOAD_PROPOSAL = "Download Proposal",
  EMAIL_PROPOSAL = "Email Proposal",
  EMAIL_PROPOSAL_CUSTOMER = "Email Proposal to Customer",
}

export enum DownloadDocument {
  QUOTATION = "quotation",
  PROPOSAL = "proposal",
}

export enum PermissionType {
  TEXT = "text",
  VIEW = "view",
}

export enum ProposalMode {
  VIEW = "view",
  EDIT = "edit",
}

export enum AssetFormField {
  NAME = "name",
  DESCRIPTION = "description",
}
export enum WorkQueueExportKeys {
  NAME = "name",
  CREATED_BY = "created_by",
  UPDATED_AT = "updated_at",
  START_DATE = "start_date",
  END_DATE = "end_date",
  STATUS = "status",
  FINANCE_TYPE = "finance_type",
  FINANCE_AMOUNT = "finance_amount",
  CUSTOMER_NAME = "customer_name",
  DATE_SUBMITED = "date_submitted",
}
