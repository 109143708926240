import { FC, useEffect, useState } from 'react'
import { useGetDealers, useGetLenderByTenantId } from '@apis/dealer-configurations.service'
import { getAllSettingsFromLocalStorage } from '@helpers/methods'
import { useStoreContext } from '@store/storeContext'
//@ts-ignore
import { useHasPermissions } from '@ntpkunity/controls-ums'
import { dealexPermissions } from '@ntpkunity/controls-ums/dealexPermissions'
import { useGetCurrencies } from '@apis/configurations.service'

const GetDealerLenderInfo: FC = ({ children }) => {
  const { mutate: getLenderByTenantId } = useGetLenderByTenantId()
  const { data: dealerAssociation, isSuccess: successDealers } = useGetDealers('Dealer')
  const [tenantId, setTenantId] = useState(0)

  useGetCurrencies(tenantId)
  const { actions } = useStoreContext()
  const hasAdminPermission = useHasPermissions(
    dealexPermissions?.BUSINESS_PERMISSIONS?.CAN_VIEW_ALL_ORDERS
  )
  if (hasAdminPermission) {
    const settings = getAllSettingsFromLocalStorage()
    settings.is_admin_ad = true
    localStorage.setItem('settings', JSON.stringify(settings))
  }
  useEffect(() => {
    if (localStorage.getItem('settings')) {
      const settings = getAllSettingsFromLocalStorage()
      settings.isDealex = true
      localStorage.setItem('settings', JSON.stringify(settings))
      if (window.location.pathname.includes('work-queue')) {
        getLenderByTenantId(
          { tenant_id: settings?.tenant_id },
          {
            onSuccess(res: any) {
              actions.setDealerAndLenderInfo(res)
            }
          }
        )
      } else if (
        !window.location.pathname.includes('sso-login') &&
        !window.location.pathname.includes('work-queue')
      ) {
        getLenderByTenantId(
          { tenant_id: settings?.tenant_id },
          {
            onSuccess(res: any) {
              actions.setLenderInfo(res)
              setTenantId(res.id)
            }
          }
        )
      }
    }
  }, [])
  useEffect(() => {
    if (
      !window.location.pathname.includes('sso-login') &&
      !window.location.pathname.includes('work-queue') &&
      successDealers
    ) {
      const settings = getAllSettingsFromLocalStorage()
      if (settings?.role?.name !== 'Admin') {
        const entityID = parseInt(localStorage.getItem('entityID') ?? '')
        if (entityID) {
          const selectedDealer = dealerAssociation.find((item: any) => item.id === entityID)
          actions.setDealerInfo(selectedDealer)
          actions.setDealerAndLenderInfo(selectedDealer)
        } else {
          actions.setDealerInfo(dealerAssociation[0])
        }
      }
    }
  }, [successDealers])

  return <>{children}</>
}

export default GetDealerLenderInfo
