import {
  ButtonGroup,
  LayoutWithSideNavComponent,
  PageHeader,
  Stack,
} from "@components";
import { Widget } from "@components/dashboard";
import { useTheme } from "@mui/material";
import { Box, Grid, Select } from "@ntpkunity/controls";
import ProposalGraph from "../../public/assets/images/proposal-source.png";
import ActiveProposalGraph from "../../public/assets/images/active-proposal-widget.png";
import TopPerformingIntroducer from "../../public/assets/images/top-performing-introducer.png";
import RevenueGraph from "../../public/assets/images/revenue-widget.png";
import ConversionRate from "../../public/assets/images/conversion-rate.png";
import { ProposalTable } from "@components/work-queue";
import { PageContent } from "./dashboard-page.style";
import { WorkQueueTabs } from "@helpers/enum";
import { DASHBOARD_RANGE_FILTER_VALUES } from "@helpers/const";

export const LenderDashboardPage = () => {
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={theme}>
      <PageHeader
        title="Dashboard"
        actionArea={
          <Select
            theme={theme}
            label="Select Introducer"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            items={[
              {
                text: "All",
                value: 0,
              },
              {
                text: "abc Deaker",
                value: "abc Deaker",
              },
              {
                text: "Dack Commercial",
                value: "Dack Commercial",
              },
              {
                text: "Grip Systems",
                value: "Grip Systems",
              },
              {
                text: "Harry",
                value: "Harry",
              },
              {
                text: "Knighton Janitorial",
                value: "Knighton Janitorial",
              },
              {
                text: "My Dealer",
                value: "My Dealer",
              },
              {
                text: "Prod Dealer",
                value: "Prod Dealer",
              },
              {
                text: "Rhino Interiors",
                value: "Rhino Interiors",
              },
            ]}
            value={0}
            disablePortal={false}
          />
        }
      />
      <PageContent theme={theme} className="page-content has-action" pt={3}>
        <Grid theme={theme} container item spacing={2} alignItems={"stretch"}>
          <Grid theme={theme} item xs={12} md={6} lg={4}>
            <Widget
              control="proposal-source"
              title={"Proposal Source"}
              actionArea={
                <>
                  <Select
                    disablePortal={false}
                    theme={theme}
                    items={DASHBOARD_RANGE_FILTER_VALUES}
                    value={"Month"}
                  />
                </>
              }
            >
              <Box theme={theme} className="graph-ph" textAlign={"center"}>
                <img src={ProposalGraph} alt="" className="mw-100" />
              </Box>
            </Widget>
          </Grid>
          <Grid theme={theme} item xs={12} md={6} lg={4}>
            <Widget
              control="active-proposal"
              title={"Active Proposals"}
              actionArea={
                <>
                  <Select
                    disablePortal={false}
                    theme={theme}
                    items={DASHBOARD_RANGE_FILTER_VALUES}
                    value={"Month"}
                  />
                </>
              }
            >
              <Box theme={theme} className="graph-ph" textAlign={"center"}>
                <img src={ActiveProposalGraph} alt="" className="mw-100" />
              </Box>
            </Widget>
          </Grid>
          <Grid theme={theme} item xs={12} md={6} lg={4}>
            <Widget
              control="Conversion Rate"
              title={"Conversion Rate"}
              actionArea={
                <>
                  <Select
                    disablePortal={false}
                    theme={theme}
                    items={DASHBOARD_RANGE_FILTER_VALUES}
                    value={"Month"}
                  />
                </>
              }
            >
              <Box theme={theme} className="graph-ph" textAlign={"center"}>
                <img src={ConversionRate} alt="" className="mw-100" />
              </Box>
            </Widget>
          </Grid>

          <Grid theme={theme} item xs={12} md={6} lg={4}>
            <Widget
              control="top-performing-brokers"
              title={"Top Performing Introducers"}
              actionArea={
                <>
                  <Select
                    disablePortal={false}
                    theme={theme}
                    items={DASHBOARD_RANGE_FILTER_VALUES}
                    value={"Month"}
                  />
                </>
              }
            >
              <Box theme={theme} className="graph-ph" textAlign={"center"}>
                <img src={TopPerformingIntroducer} alt="" className="mw-100" />
              </Box>
            </Widget>
          </Grid>
          <Grid theme={theme} item xs={12} md={12} lg={8}>
            <Widget
              control="total-revenue"
              title={"Total Revenue"}
              actionArea={
                <>
                  <ButtonGroup
                    items={[
                      {
                        title: "Daily",
                        onClick: () => {},
                        selected: false,
                      },
                      {
                        title: "Weekly",
                        onClick: () => {},
                        selected: true,
                      },
                      {
                        title: "Monthly",
                        onClick: () => {},
                        selected: false,
                      },
                      {
                        title: "Yearly",
                        onClick: () => {},
                        selected: false,
                      },
                    ]}
                  />
                </>
              }
            >
              <Box theme={theme} className="graph-ph" textAlign={"center"}>
                <img src={RevenueGraph} alt="" className="mw-100" />
              </Box>
            </Widget>
          </Grid>
          <Grid theme={theme} item xs={12}>
            <ProposalTable
              hasCreateNew={false}
              queue={WorkQueueTabs.TRACK_PROPOSALS}
            />
          </Grid>
        </Grid>
      </PageContent>
    </LayoutWithSideNavComponent>
  );
};
