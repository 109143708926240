import './root.component.css'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { Slide, StyledEngineProvider, useTheme } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import Router from './router'
import StoreProvider, { useStoreContext } from '@store/storeContext'
import { ModalProvider } from 'react-modal-hook'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Button, Snackbar, unityTheme } from '@ntpkunity/controls'
import { GetDealerLenderInfo } from '@app/get-dealer-lender-info'
import CheckLogin from '@app/sso/components/check-login'
import {
  LoginValidatorComponent,
  UMSConfigurationComponent
  //@ts-ignore
} from '@ntpkunity/controls-ums'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './authConfig'
// import { MsalProvider } from '@azure/msal-react'
import { CustomTheme } from '@styles/customTheme'
import { lightTheme } from '@styles/theme'
import { useEffect, useState } from 'react'
import {
  UseQuery_getLenderByTenantId,
  UseQuery_getThemeBuilderbyEmail
} from '@apis/configurations.service'
import { IThemeBuilder } from '@models'
import { getAllSettingsFromLocalStorage } from '@helpers/methods'
import { setFavicon, setLogo, setStyleSheet } from 'favicon'
import { ChatButton } from '@components'
//@ts-ignore
export const msalInstance = new PublicClientApplication(msalConfig)
export default function Root(props: any) {
  const [ready, setReady] = useState(false)
  const onReady = () => {
    if (!ready) setReady(true)
  }
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity
      }
    }
  })
  document.title = 'Lane'

  return (
    <LoginValidatorComponent url={process.env.CCP_CUSTOMERS_LIST_SCREEN} onReady={onReady}>
      {ready && (
        <UMSConfigurationComponent>
          {/* <MsalProvider instance={msalInstance}> */}
          <QueryClientProvider client={queryClient}>
            <CheckLogin>
              <StoreProvider>
                <GetDealerLenderInfo>
                  <App {...props} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </GetDealerLenderInfo>
              </StoreProvider>
            </CheckLogin>
          </QueryClientProvider>
          {/* </MsalProvider> */}
        </UMSConfigurationComponent>
      )}
    </LoginValidatorComponent>
  )
}

const App = (_props: any) => {
  const { states, actions } = useStoreContext()
  const { toastData } = states
  const onClose = (_e: any, reason: any) => {
    if (reason !== 'clickaway') {
      actions.setToast({ toastMessage: '', toastState: false })
    }
  }
  const theme = useTheme()
  const settings = getAllSettingsFromLocalStorage()
  const [userEmail, setUserEmail] = useState<string>('')
  const { data: lenderInfo } = UseQuery_getLenderByTenantId(settings?.tenant_id)
  const lenderProfile: any = lenderInfo
  const { data } = UseQuery_getThemeBuilderbyEmail(userEmail)
  const ThemeBuilderData: IThemeBuilder = data as IThemeBuilder

  useEffect(() => {
    if (lenderProfile) {
      if (settings?.role?.name == 'Admin') {
        setUserEmail(lenderProfile?.email)
      } else {
        setUserEmail(states?.dealerInfo?.email)
      }
    }
  }, [lenderProfile, states?.dealerInfo?.email])
  useEffect(() => {
    if (ThemeBuilderData) {
      setStyleSheet(ThemeBuilderData.stylesheet)
      setFavicon(ThemeBuilderData?.favicon?.location)
      setLogo(ThemeBuilderData?.logo?.location)
    }
  }, [ThemeBuilderData])

  return (
    <>
      <StyledEngineProvider injectFirst>
        {/* <MuiThemeProvider theme={states.theme?.theme as any}> */}
        <MuiThemeProvider theme={lightTheme}>
          <CustomTheme className="custom-theme" theme={theme}>
            <Snackbar
              action={toastData?.showButton ? toastData?.showButton : toastData?.showIcon ? {} : ''}
              theme={unityTheme}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              autoHideDuration={
                toastData?.showIcon || toastData?.showButton
                  ? null
                  : toastData?.toastLength
                  ? toastData.toastLength
                  : 5000
              }
              variant={toastData?.variant}
              open={toastData?.toastState ? toastData?.toastState : false}
              onClose={(e, reason) => {
                onClose(e, reason)
              }}
              message={toastData?.toastMessage ? toastData?.toastMessage : ''}
              TransitionComponent={(props) => <Slide {...props} direction="up" />}
              btn={
                <>
                  <Button
                    theme={theme}
                    onClick={() => {
                      toastData?.yesButtonOnClick?.()
                      actions.setToast({ toastMessage: '', toastState: false })
                    }}
                    text="Yes"
                  />
                  <Button
                    theme={theme}
                    onClick={() => {
                      toastData?.noButtonOnClick?.()
                      actions.setToast({ toastMessage: '', toastState: false })
                    }}
                    text="No"
                  />
                </>
              }
              noBtn={true}
            />
            <ModalProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </ModalProvider>
            <ChatButton />
          </CustomTheme>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </>
  )
}
