import "./root.component.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router";
// import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material";
import { UMSConfigurationComponent ,LoginValidatorComponent} from "@ntpkunity/controls-ums";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { unityTheme } from "@ntpkunity/controls";
import { QueryClientProvider, QueryClient } from "react-query";
import StoreProvider from "../src/store/storeContext";
import BaseLayout from "@layout/components/baseLayout/baseLayout";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
export const msalInstance = new PublicClientApplication(msalConfig);

// import { HomeComponent } from "./components/home";
// const APPLICATION_ROUTES = () => {
//   return (
//     <Routes>
//       <Route path="/" element={<HomeComponent />}>
//         <Route path="/test-url" element={<HomeComponent />} />
//         <Route path="/ccp/test-url" element={<HomeComponent />} />
//         <Route path="*" />
//       </Route>
//     </Routes>
//   );
// };

export default function Root(props) {
  return <App {...props} />;
}

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: 1,
      },
    },
  });
  return (
    <UMSConfigurationComponent>
    <LoginValidatorComponent>
    <StoreProvider>
      {/* <MsalProvider instance={msalInstance}> */}
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={unityTheme}>
              <BaseLayout>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
                {/*<BrowserRouter>*/}
                {/*  <APPLICATION_ROUTES />*/}
                {/*</BrowserRouter>*/}
              </BaseLayout>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      {/* </MsalProvider> */}
    </StoreProvider>
    </LoginValidatorComponent>
    </UMSConfigurationComponent>
  );
};
